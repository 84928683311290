import { createEvent, createStore } from 'effector';

export const updateCache = createEvent<any>()

const $cache = createStore<any>({})

$cache.on(updateCache, (state, nextState) => {
  return ({ ...state, ...nextState })
})

export default $cache;