export const config: any = {
    "DEV": {
        "gotovitBackend": "https://gotovit-backend-test.vkusvill.ru/api/v1",
        "app": "https://test.vkusvill.ru:40113",
        "api": "https://vvf.walnut.one",
        "baseAuth": "d2FsbnV0OldudmdCY1ZoZ3g="
    },
    "PROD": {
        "gotovitBackend": "https://gotovit-backend.vkusvill.ru/api/v1",
        "app": "https://mobile.vkusvill.ru",
        "api": "https://food.vkusvill.ru",
        "baseAuth": "d2FsbnV0OldudmdCY1ZoZ3g="
    }
};