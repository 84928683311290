interface getInterface {
  externalUpdate: (value: any) => getInterface
  showExternalChanged: (value?: any) => getInterface
}

export class CE {
  elements: any = {};

  add(name: string, context: any) {
    this.elements[name] = context;
  }

  remove(name: string) {
    delete this.elements[name]
  }

  get(name: string): getInterface {
    const element = this.elements[name];
    return element
  }
}

export default CE;