import setClasses from '@helpers/setClasses';
import React from 'react';
import TouchableOpacity from './touchableOpacity';
import ReactComponent from "@abstract/reactComponent";

interface ConfigInterface {
  title?: string | undefined
  description?: string | undefined
  status: 'success' | 'error' | 'info'
  timeout?: number
}
interface P { }
interface S {
  config?: ConfigInterface | null
  isOpen: boolean
}

export class Box extends ReactComponent<P, S> {
  timeout!: ReturnType<typeof setTimeout>;
  closeTimeout!: ReturnType<typeof setTimeout>;

  constructor(props: P) {
    super(props)

    this.state = {
      isOpen: false,
      config: null
    } as S;
  }

  componentDidMount() {
    window.box = this;
  }

  open = (config: ConfigInterface) => {
    clearTimeout(this.timeout)
    this.setState({ config, isOpen: true }, () => {
      this.timeout = setTimeout(() => {
        this.close()
      }, config.timeout || 2000);
    })
  }

  close = () => this.setState({ isOpen: false }, () => {
    this.closeTimeout = setTimeout(() => {
      this.setState({ config: null })
    }, 300);
  })

  renderBox(config?: ConfigInterface | null, isOpen?: boolean) {
    const appereanses: { success: string, error: string, info: string } = {
      // success: 'bg-green-400 text-white',
      // error: 'bg-red-600 text-white',
      // info: 'bg-gray-400 text-white',
      success: 'bg-box text-white',
      error: 'bg-box text-white',
      info: 'bg-box text-white',
    }

    if (!config) return null

    return (
      <div className="fixed w-full left-0 top-10 z-50 flex justify-center items-center px-4">
        <div className={setClasses(['animate__animated animate__faster w-full  rounded-lg text-center shadow flex', appereanses[config.status]], {
          "animate__fadeInDown": isOpen,
          "animate__fadeOutUp": !isOpen
        })}>
          <div className="absolute left-3 top-3">
            <TouchableOpacity space={5} onClick={() => this.close()}>
              <img className="w-4 h-4" alt="" src="/img/closebox.png" />
            </TouchableOpacity>
          </div>
          <div className="w-full pr-3 pl-10 py-3">
            <div className="owerflow-hidden elipsiss w-full text-left mb-1 text-sm font-bold ">{config.title || 'Unknown Error'}</div>
            {config.description && <div className="w-full text-left text-sm font-medium">{config.description}</div>}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { isOpen, config } = this.state
    return (
      <>
        {this.renderBox(config, isOpen)}
      </>
    )
  }
}

export default Box;