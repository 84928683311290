import Page, { P, S as ParentS } from '@abstract/page';
import jsToParams from '@helpers/jsToParams';
import { ConfigInterface } from '@abstract/constructor';
import { ValueFromConfig } from '@abstract/constructorElement';

interface S extends ParentS {
  config: ConfigInterface & {
    generator?: {
      url: string
      headers: any
      params: any
    } | undefined;
  }
}

export class Generator extends Page<P, S> {
  get config() {
    return { url: this.URLS.generator };
  }

  async componentDidMount() {
    await super.componentDidMount()
    const { generator } = this.state.config;
    if (generator && generator.url) {
      fetch(ValueFromConfig(generator.url), {
        headers: generator.headers
      })
        .then(res => res.json())
        .then(({ uuid }) => {
          const strParams = decodeURIComponent(jsToParams({ ...generator?.params, uuid }))
          this.props.history.push(`/${strParams}`)
        })
    }
  }
}

export default Generator;