import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export interface Props {
  type: 'mobileBanner',
}

export class MobileBanner extends ReactComponent<Props, {}> {
  public render(): JSX.Element {
    return (
      <div className='px-4 text-16px font-bold text-center text-primary'>
        Вы можете скачать приложение «ВкусМил: рационы питания» в AppStore и Google Play
      </div>
    )
  }
}

export default MobileBanner;
