export function getParam(key) {
  var p = window.location.search;
  p = p.match(new RegExp(key + '=([^&=]+)'));
  var result = p ? p[1] : false;
  if (result) {
    result = decodeURIComponent(result)
  }
  return result
}

export default getParam;