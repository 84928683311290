import ReactComponent from '@abstract/reactComponent';
import Image from '@components/base/image';
import Scroll from '@components/base/scroll';
import TouchableOpacity from '@components/base/touchableOpacity';
import declOfNum from '@helpers/declOfNum';
import setClasses from '@helpers/setClasses';
import { ReactComponent as Close } from '@img/close.svg';
import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import Address from '../address';
import { ListItem, Statuses } from './list';
import { ReactComponent as Clock } from '@img/clock.svg';
import { ReactComponent as Calendar } from '@img/calendar.svg';

interface P {
  selected?: null | false | ListItem
  className: string
  onClose: Function
  onCloseInner: Function
}

export class Inner extends ReactComponent<P> {
  renderInfo({ icon, title, value }: { icon: any, title: string, value?: string }): ReactNode {
    const Icon = icon;
    return (
      <div className="flex mr-3 last:mr-0">
        {icon && <Icon className="mr-2" />}
        <div>
          <div className="text-13px">{title}</div>
          <div className="text-15px text-datepicker">{value}</div>
        </div>
      </div>
    )
  }

  renderStatusAndPaid(): string {
    let result: string = '';
    const { selected } = this.props;
    if (selected) {
      const values: [string, string] = [Statuses[selected.status], selected.paid ? 'оплачен' : 'не оплачен']
      result = values.join('\n и ')
    }

    return result
  }

  render() {
    const { className, onCloseInner, onClose, selected } = this.props;
    if (!selected) return false

    return (
      <div className={setClasses(['bg-white w-full h-full absolute left-0 top-0 z-20 flex flex-col rounded-t-20px', className])}>
        <div className="bg-alertBg px-4 py-5 rounded-20px border border-solid border-hr border-opacity-40">
          <div className="flex justify-between w-full mb-5">
            <div className="font-black text-28px">О заказе</div>
            <TouchableOpacity onClick={onClose}>
              <Close />
            </TouchableOpacity>
          </div>

          <div className="flex justify-between text-sm font-bold w-full bg-white shadow rounded-15px py-3 px-4 mb-4">
            <div>
              <div className="text-14px">Заказ №{selected.id}</div>
              <div className="text-15px text-cgrey">
                <span>{dayjs(selected.datetime).format('DD.MM.YYYY')}</span>
                <span className="mx-0.5">•</span>
                <span>{dayjs(selected.datetime).format('HH:mm')}</span>
              </div>
            </div>
            <div className="text-14px text-right text-primary whitespace-pre-wrap">{this.renderStatusAndPaid()}</div>
          </div>

          <div className="mb-5">
            <div className="text-16px leading-19.2px tracking-0.15px">Адрес доставки</div>
            <div className="text-16px leading-19.2px tracking-0.15px mt-1">{selected.address.title}</div>
            <div className="text-gray-500 text-12px leading-14.4px  tracking-0.15px whitespace-nowrap overflow-ellipsis w-full overflow-hidden mt-1">{Address.getSubTitle(selected.address)}</div>
          </div>


          <div className="mb-2.5">
            <TouchableOpacity className="w-full" onClick={() => onCloseInner()}>
              <div className="flex justify-center text-sm font-bold w-full bg-white shadow rounded-15px py-3 px-4">
                <div>Все заказы</div>
              </div>
            </TouchableOpacity>
          </div>
        </div>
        <Scroll enableTopShadow enableBottomShadow className="py-3" containerClassName="w-full flex-1 my-1">
          <div className="px-4">
            {selected.basket.map((product) => (
              <div key={product?.id} className="flex w-full py-5 border-b border-solid border-hr">
                <Image className="rounded-10px w-16 h-11 mr-2 overflow-hidden" src={product?.photo || ''} />
                <div>
                  <div className="text-15px font-bold">{product?.name}</div>
                  <div className="text-15px font-bold flex justify-between my-3">
                    <div>{product?.sum} ₽</div>
                    <div>{product?.quantity} {declOfNum(product?.quantity || 1, ['день', 'дня', 'дней'])}</div>
                  </div>
                  <div className="flex justify-between">
                    {this.renderInfo({
                      icon: Calendar,
                      title: 'Даты доставки',
                      value: product?.date
                    })}
                    {this.renderInfo({
                      icon: Clock,
                      title: 'Время доставки',
                      value: product?.time
                    })}
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-5">
              <div className="text-12px text-datepicker mb-1">Комментарий</div>
              <div className="text-16px mb-5">{selected.comment}</div>
              <div className="text-16px flex w-full justify-between">
                <div>Общая сумма заказа</div>
                <div className="font-bold text-primary">{selected.price} ₽</div>
              </div>
            </div>
          </div>
        </Scroll>
      </div>
    )
  }
}

export default Inner;