import React from 'react';
import Header, { P as HeaderInterface } from '@components/constructor/header';
import Components from '@components/constructor';
import Block from '@components/constructor/block';
import ReactComponent from "@abstract/reactComponent";
import { RenderDebug } from '../effector/debug';

export interface ParamInterface {
  name: string
  value: string
  type?: string
}


export interface ConfigInterface {
  global: ParamInterface[]
  request: any,
  header: HeaderInterface,
  top: any[],
  content: any[],
}

const BlackList = ['title', 'price', 'info', 'switch', 'payment', 'detailSubTitle', 'image', 'notdelivery', 'space', 'datepicker', 'input'] // disable wrapping to block

export class Constructor extends ReactComponent {
  config: ConfigInterface;
  getProps: Function;
  constructor(config: ConfigInterface, getProps: Function) {
    super({});
    this.config = config;
    this.getProps = getProps;
  }

  public static getComponents = (list: any[], requestSettings: any, getProps: Function, wrapToBlock: boolean, config?: ConfigInterface): JSX.Element[] => {
    return list.map((item: any, key: number) => Constructor.getComponent(item, key, requestSettings, getProps, wrapToBlock, config));
  }

  public static getComponent = (item: any, key: number, requestSettings: any, getProps: Function, wrapToBlock: boolean, config?: ConfigInterface): JSX.Element => {
    if (Object.keys(Components).indexOf(item.type) === -1) throw new Error(`Please connect component "${item.type}" to /src/components/constructor/index.ts and reload your page`)
    const Component: any = Components[item.type];
    const props = {
      getProps,
      requestSettings,
      _key: key,
      config,
      ...item
    }

    const Wrapper = wrapToBlock && BlackList.indexOf(item.type) === -1 ? (
      <Block key={key}>
        <Component {...props} />
      </Block>
    ) : <Component {...props} key={key} />;

    return Wrapper;
  }

  Header = () => {
    return <Header {...this.config?.header} getProps={this.getProps} />
  }

  Top = () => this.config?.top && Constructor.getComponents(this.config.top, this.config?.request, this.getProps, false, this.config)

  Content = () => {
    return (
      <div>
        {Constructor.getComponents(this.config?.content, this.config?.request, this.getProps, true, this.config)}
        <RenderDebug />
      </div>
    )
  }
}

export default Constructor;