import React from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import { Link } from 'react-router-dom';
import ReactComponent from "@abstract/reactComponent";

interface P {
  href: string;
  onClick: Function;
}

export class A extends ReactComponent<P, {}> {
  public render(): JSX.Element {
    const { children, href, onClick } = this.props;
    const Text = href ? Link : ({ children, className }: any) => <div className={className}>{children}</div>

    return (
      <TouchableOpacity onClick={onClick}>
        <Text to={href} className="text-primary underline leading-19.6px text-14px">{children}</Text>
      </TouchableOpacity>
    )
  }

  public static defaultProps = {
    href: '',
    onClick: () => false
  };
}

export default A;