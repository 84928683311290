import { createEvent, createStore } from 'effector';

interface InterfaceAddress {
  selected: number | string
}

export const updateAddress = createEvent<InterfaceAddress>()

const $address = createStore<InterfaceAddress>({ selected: 0 })

$address.on(updateAddress, (_, nextState) => nextState)

export default $address;