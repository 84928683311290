import ModalAlert from '@components/base/alert';
import { ReactNode } from 'react';

export class ModalEmail extends ModalAlert {
  _renderTitle(title?: string | ReactNode) {
    return (
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center z-10">
        <div className="mt-1 w-full text-left pl-4">{title}</div>
      </div>
    )
  }
  
  render() {
    const data: any = this.state.data;
    return this._render({
      title: data?.title,
      children: (
        <div className="px-4">
          {data?.item?.title}
        </div>
      ),
      controlsSettings: data?.controlsSettings
    })
  }
}

export default ModalEmail;