import dispatchEvent from './dispatchEvent';
import jsToParams from './jsToParams';
import global, {updateGlobal} from "@effector/global";
import $global from "@effector/global";
import {config} from "@helpers/config";
import $cache from "@effector/cache";

interface InputInterface {
  url: string
  method?: string
  body?: object
  params?: object
  headers?: object
  ignoreJson?: boolean
  abortController?: AbortController
  eventName?: string
}

export default function request({ url, method, body, params, headers, ignoreJson, abortController, eventName }: InputInterface, setPreloader: Function) {
  if (!headers) {
    headers = {}
  }

  return new Promise((resolve, reject) => {
    const mode: any = Boolean(config.url?.search('.dev.') === -1) ? 'PROD' : 'DEV';
    let bodyConditions: any = body;

    const transformData = () => {
      if (bodyConditions.email === 'null') {
        bodyConditions.email = null;
      }

      if (bodyConditions.cutlery === 'null') {
        bodyConditions.cutlery = null;
      }

      if (bodyConditions.needCall === 'null') {
        bodyConditions.needCall = null;
      }

      if (bodyConditions.needPermit === 'null') {
        bodyConditions.needPermit = null;
      }

      // eslint-disable-next-line no-template-curly-in-string
      if (bodyConditions.comment === '${address.value.comments}') {
        bodyConditions.comment = '';
      }

      // eslint-disable-next-line no-template-curly-in-string
      if (bodyConditions.coupon === '$$${global.coupon}') {
        bodyConditions.coupon = $global.getState().coupon;
      }

      if (bodyConditions.phone === 'null') {
        bodyConditions.phone = $cache.getState().phone.phone;
      }
    }

    if (mode === 'DEV') {
      if (url === 'https://vvf.walnut.one/api/v1/order/ability') {
        transformData();
      }
    } else {
      if (url === 'https://food.vkusvill.ru/api/v1/order/ability') {
        transformData();
      }
    }

    if (body && (eventName === 'calendarConditions' || eventName === 'disableddates' || eventName === 'intervals' || eventName === 'abilityRequestCalendar')) {
      let basketArr: object[] = [];

      if (eventName === 'disableddates' || eventName === 'intervals' || eventName === 'abilityRequestCalendar') {
        JSON.parse(bodyConditions.basket).forEach((item: any) => {
          basketArr.push({'productId': parseInt(item.productId), 'quantity': parseInt(item.deliveryDaysAll)})
        })
      } else {
        JSON.parse(bodyConditions.basket).forEach((item: any) => {
          basketArr.push({'productId': parseInt(item.productId)})
        })
      }

      bodyConditions.basket = basketArr;

      if (eventName === 'abilityRequestCalendar') {
        bodyConditions.time = bodyConditions.time === 'null' ? null : bodyConditions.time
      }
    }

    setPreloader(true);
    fetch(`${url}${jsToParams(params)}`, {
      method: method || 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      ...(abortController ? { signal: abortController.signal } : {}),
      ...(method?.toUpperCase() !== 'GET' ?
        eventName !== 'calendarConditions' && eventName !== 'disableddates' && eventName !== 'intervals' ?
          { body: JSON.stringify(body) } : bodyConditions ?
            { body: JSON.stringify(bodyConditions) } : {} : {}),
    })
      .then(async (res) => {
        let _res: any = res
        if (!ignoreJson) {
          _res = await res.json()
        }
        else {
          _res = { response: await res.text() };
        }

        if (res.status !== 200 && res.status !== 201) {
          //error
          setPreloader(false)
          reject({ ..._res })
          return res;
        }

        if (eventName === 'xvkusvilltoken') {
          if (_res.number !== global.getState().card) {
            console.log('Карты не совпали', _res.number, global.getState().card);
            return new Response(null);
          }
        }

        setPreloader(false)
        resolve({ ..._res })
        dispatchEvent(`${eventName}_done`, { ..._res })

        if (eventName === 'calendarConditions') {
          updateGlobal({ "calendarConditionsObj": _res })
        }

        if (eventName === 'abilityRequestCalendar') {
          updateGlobal({ "abilityCalendarDate": _res })
        }

        return res
      })
      .catch((err) => {
        setPreloader(false)
        reject(err)
        dispatchEvent(`${eventName}_fail`, err)
        return err
      })
      .then((data) => {
        dispatchEvent(`${eventName}_end`, data)
      })
  });
}
