import setClasses from '@helpers/setClasses';
import React from 'react';

export function Pulse({ children, isActive, className }: { children: any, isActive: boolean, className?: string }): JSX.Element {
  return (
    <div className={setClasses(['relative', className])}>
      {isActive && <div className="absolute fillPulse w-full h-full animate__animated animate__pulse animate__infinite infinite" />}
      {children}
    </div>
  )
}

export default Pulse;