import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS } from '@abstract/constructorElement';

export interface P extends ConstructorElementInterfaceP {
  type: 'mount';
}

export class Mount extends ConstructorElement<P, ConstructorElementInterfaceS> {
  _isMounted: boolean = false;

  mount() {
    if (!this._isMounted) {
      this._isMounted = true;
      this.log()
    }
  }

  unmount() {
    if (this._isMounted) {
      this._isMounted = false;
    }
  }

  lifeCycle() {
    const checkConditions = this.checkConditions();
    if (checkConditions) this.mount()
    else this.unmount()
  }

  public render(): JSX.Element {
    this.lifeCycle()
    return <></>
  }
}

export default Mount;