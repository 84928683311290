import Counter, { P as PP } from './counter';

interface P extends PP {
  prices: { count: number, price: number, discountPrice: number }[]
  subtract: Function
  add: Function
}
export class CustomCounter extends Counter<P> {
  getIndexByValue(): number {
    let index: number = 0;
    this.props.prices.forEach((item, key: number) => {
      if (item.count === this.state.value) {
        index = key;
      }
    })

    return index;
  }

  apply(index: number) {
    this.setState({ value: this.props.prices[index].count }, () => {
      this.onChange()
    })
  }

  subtract() {
    let index = this.getIndexByValue();
    if (index !== 0) {
      index -= 1;
    }
    this.props.subtract()
    this.apply(index)
  }

  add() {
    let index = this.getIndexByValue();
    if (index !== this.props.prices.length - 1) {
      index += 1;
    }

    this.props.add()
    this.apply(index)
  }

  onChange() {
    const { onChange } = this.props;
    const { value } = this.state;

    const itemPrices = this.props.prices[this.getIndexByValue()];
    const currentPrice = itemPrices.discountPrice || itemPrices.price;

    if (onChange) onChange(value, currentPrice);
  }
}

export default CustomCounter;