import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export class Block extends ReactComponent {
  public render(): JSX.Element {
    const { children } = this.props;
    return (
      <div className="w-full mb-8 last:mb-0">
        {children}
      </div>
    )
  }
}

export default Block;