import React from 'react';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import Ghost from '@components/base/ghost';
import {updateGlobal} from "@effector/global";

export interface DietDetailInterface {
  title: string;
  subTitle: string;
  id: any;
  viewKbju?: boolean;
}

export interface P {
  type: 'info';
  active: string
  name?: string;
  list?: DietDetailInterface[];
  getProps: Function;
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
}

export class Info extends ConstructorElement<P, S> {
  renderPreloader() {
    const elements: number[] = [0, 1, 2];

    return (
      <div className="w-full flex pl-4 h-10 mb-5">
        {elements.map(key => (
          <Ghost key={key} className={'mr-7 px-2 last:mr-0 w-20'}>
            {Ghost.Text('w-full')}
            {Ghost.Text('w-1/2')}
          </Ghost>
        ))}
      </div>
    )
  }

  public render(): JSX.Element {
    const { list } = this.props;
    const { data } = this.state;

    const _list: DietDetailInterface[] = (list || data)?.find((it: any) => {
      if (it) {
        const [first] = it;
        if (first) {
          // eslint-disable-next-line eqeqeq
          return first.id == this.parseValue(this.props.active, this.state.observeValues, {}, true)[0]
        }
      }
      return false
    });

    updateGlobal({ "viewKbju": _list && _list[0].viewKbju });

    // Не выводим общее КБЖУ заменяем его КБЖУ дня
    // if (_list && _list[0].viewKbju) {
    //   return (
    //     this._render(
    //       <div className="mb-5 h-10">
    //         <div className="px-4 flex justify-center">
    //           {_list?.map((props: any, key: number) => (
    //             <div key={key} className="text-calorificDetail mr-4 last:mr-0 w-16">
    //               <div className="text-12px font-semibold">{props.title}</div>
    //               <div className="text-12px">{props.subTitle}</div>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     )
    //   )
    // } else {
    //   return <></>
    // }

    return <></>
  }
}

export default Info;