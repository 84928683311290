import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import setClasses from '@helpers/setClasses';
import React from 'react';

export interface P {
  type: 'price';
  title: string;
  price?: any,
  maxPrice: any,
  sum: any,
  sumMax: any,
  currency?: string,
  appereance: string
  getProps: Function
}

const APPEREANCES: any = {
  default: 'text-14px leading-24px',
  shop: 'text-16px leading-19px',
}

declare global {
  interface Window { debugVar: any }
}

export class Price extends ConstructorElement<P, ConstructorElementInterfaceS> {
  componentDidMount() {
    window.debugVar = this;
    super.componentDidMount()
    document.addEventListener('updatePrice', this.updatePrice)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener('updatePrice', this.updatePrice)
  }

  updatePrice = () => {
    this.forceUpdate()
  }

  getPrice = (price: string | undefined) => {
    return this.parseValue("" + price, this.state.observeValues, {}, true)
  }

  getMaxPrice = () => {
    return this.parseValue("" + this.props.maxPrice, this.state.observeValues, {}, true)
  }

  getSum = () => {
    return this.parseValue("" + this.props.sum, this.state.observeValues, {}, true)
  }

  getSumMax = () => {
    return this.parseValue("" + this.props.sumMax, this.state.observeValues, {}, true)
  }

  public render(): JSX.Element {
    const { title, currency, appereance } = this.props;
    const _appereance: string = appereance ? APPEREANCES[appereance] : APPEREANCES.default;
    const price: number = parseInt(this.getPrice(this.props.price)[0]);
    const maxPrice: number = parseInt(this.getMaxPrice()[0]);
    const sumMax: number = parseInt(this.getSumMax()[0]);
    const sum: number = parseInt(this.getSum()[0]);

    return this._render(
      <>
        <div className="flex px-4 mb-3 justify-center items-center text-14px">
          <span className={setClasses(['mr-3 text-14px', _appereance])}>{title}</span>
          <span className={setClasses(["text-primary", _appereance])}>
            {price}
            <span>{currency}</span>
          </span>
          {
            maxPrice > price &&
              <span className="text-link fz-13px ml-3 discount-price">{maxPrice}<span
                  className='text-14px'>{currency}</span></span>}
        </div>
        {sum !== -1 && <div className="flex px-4 mb-3 justify-center items-center">
          <span className="text-18px mr-3"><span>Итого: </span>
            <span className="text-15px text-primary">{sum} ₽</span>
          </span>
          {sumMax > sum && <span className="text-15px text-link discount-price">{sumMax} ₽</span>}
        </div>}
        {
          sumMax - sum > 0 && <div className="flex px-4 mb-3 justify-center items-center">
              <span className="text-14px mr-3"><span>Ваша выгода: </span>
                <span className="text-primary">{sumMax - sum} ₽</span>
              </span>
            </div>
        }
      </>
    )
  }
}

export default Price;
