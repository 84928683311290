import setClasses from '@helpers/setClasses';
import { ChangeEvent } from 'react';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import { updateGlobal } from '@effector/global';

export interface P {
  type: 'input'
  getProps: Function
  className?: string
  placeholder?: string
  isBcg?: boolean
  isCoupon?: boolean
}


interface S extends ConstructorElementInterfaceS {
  value: string
}

export class Input extends ConstructorElement<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      ...this.state,
      value: ''
    };
  }

  componentDidMount() {
    super.componentDidMount()
    document.addEventListener('clearCoupon', this.clearCoupon)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    document.removeEventListener('clearCoupon', this.clearCoupon)
  }

  clearCoupon = () => {
    this.setState({ value: '' })
    updateGlobal({ "coupon": '' })
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const { isCoupon } = this.props;

    this.setState({ value })

    if (isCoupon) {
      updateGlobal({ "coupon": value })
    }
  }

  render() {
    const { className, placeholder, isBcg } = this.props;
    const { value } = this.state;

    return (
      <div className="px-4">
        <input
          className={setClasses(['w-full outline-none', className], {
            'border-b border-solid border-black': !isBcg,
            'py-4 bg-bg px-4 rounded-10px': isBcg
          })}
          onChange={(e) => this.onChange(e)}
          placeholder={placeholder}
          value={value}
        />
      </div>
    )
  }
}

export default Input;
