import Store from '@helpers/store';
import { createEvent, createStore } from 'effector';

export const updateGlobal = createEvent<any>()

const $global = createStore<any>({})

$global.on(updateGlobal, (state, nextState) => {
  Store.setAll(nextState)
  return ({ ...state, ...nextState })
})

export default $global;