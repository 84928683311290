import TouchableOpacity from '@components/base/touchableOpacity';
import ReactComponent from "@abstract/reactComponent";
import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS } from '@abstract/constructorElement';
import { ReactComponent as Cart } from '@img/cart.svg';

export interface P extends ConstructorElementInterfaceP {
  type: 'basket',
  title?: string;
  value?: any;
  summary?: string;
}

export interface S extends ConstructorElementInterfaceS {
  orderItems: number;
  summary: number;
}

interface LayoutP {
  onClick: Function;
}

class Layout extends ReactComponent<LayoutP, {}> {
  render() {
    const { children, onClick } = this.props;
    return <TouchableOpacity className="fixed z-20 bottom-2 w-full" onClick={onClick}>{children}</TouchableOpacity>
  }
}

export class Basket extends ConstructorElement<P, S> {
  getConst() {
    const { value } = this.props;
    const orderItems = value ? this.parseValue(value)[1].length : value;
    let summ = 0

    this.parseValue(value)[1].forEach((item: any, i: number) => {
      const prices = JSON.parse(item.prices);
      
      prices.forEach((priceItem: any, i: number) => {
        if (priceItem.count === parseInt(item.deliveryDaysAll)) {
          summ += priceItem.price * parseInt(item.deliveryDaysAll)
        }
      })
    })
    
    return { orderItems, summ }
  }

  onClick = () => {
    const { orderItems, summ } = this.getConst()

    if (orderItems && summ) {
      this.props.getProps().history.push('/shop')
    }
  }

  public render(): JSX.Element {
    const { title } = this.props;

    const { orderItems, summ } = this.getConst()

    if (!orderItems && !summ) return <></>

    return (
      <div className="h-10 my-2 w-full">
        <Layout onClick={this.onClick}>
          <div className="flex items-center text-white overflow-hidden px-4 py-3 bg-primary rounded-full mx-auto w-min max-w-xs">
            <Cart className="min-w-max" />

            <div className="flex items-center pl-2 overflow-hidden">
              {!!orderItems && <span>{orderItems}</span>}
              {!!title && (
                <div className="pl-4 truncate">
                  <span>{title}</span>
                  {!!summ && <span className="pl-2 opacity-70">{summ}₽</span>}
                </div>
              )}
            </div>

            <div className="ml-auto pl-8">
              <div className="fa fa-angle-right text-white fa-lg" />
            </div>

          </div>
        </Layout>
      </div>
    )
  }
}

export default Basket;