export const setClasses = (defaultClasses: any[], isActiveClasses?: any) => {
  //defaultClasses = ['className']
  //isActiveClasses = { className: 'condition' }
  let classNames: string[] = [],
    className: string = '';

  //handling defaultClasses
  if (defaultClasses) {
    classNames = defaultClasses;
  }

  //handling active classes
  if (isActiveClasses) {
    classNames.push(...Object.keys(isActiveClasses)
      .map((className) => {
        if (isActiveClasses[className]) {
          return className
        }
        return ''
      })
      .filter((className) => (className)));
  }

  //joining classes
  className = classNames.join(' ');

  return className;
}

export default setClasses;