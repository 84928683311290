import { updateOrder } from "@effector/order";
// import dayjs from "dayjs";
import Store from "./store";

interface OrderStoreConfig {
  prefix?: string
}

export class OrderStore {
  prefix: string = 'orderStore';

  constructor(orderStoreConfig?: OrderStoreConfig) {
    if (orderStoreConfig?.prefix) {
      this.prefix = orderStoreConfig.prefix
    }
  }

  _keysName(): string {
    return `${this.prefix}keys`
  }

  async keys() {
    let list: any[] = [];

    let isExist = await Store.get(this._keysName())
    if (isExist) {
      try {
        list = JSON.parse(isExist)
      } catch (error) {
        throw new Error('Keys parse error');
      }
    }

    return list;
  }

  async _addKey(name: string): Promise<any> {
    let list: any[] = await this.keys();

    list = list.filter(_name => _name !== name);
    list.push(name);

    return Store.set(this._keysName(), JSON.stringify(list))
  }

  async _removeKey(name: string): Promise<any> {
    let list: any[] = await this.keys();

    list = list.filter(_name => _name !== name);

    return Store.set(this._keysName(), JSON.stringify(list))
  }

  _rn(name: string): string {
    return `${this.prefix}_${name}`
  }

  async replace(name: string, value: any): Promise<any> {
    let jsonValue;
    try {
      jsonValue = JSON.stringify(value);
    } catch (error) {
      throw new Error('Value can\'t be converted to json')
    }

    updateOrder([...[], ...value]);
    return await Store.set(this._rn(name), jsonValue)
  }

  async add(name: string, value: any): Promise<any> {
    let jsonValue;
    let isExist = await this.isExist(name);
    if (!isExist) {
      this._addKey(name);
    }

    try {
      jsonValue = JSON.stringify(value);
    } catch (error) {
      throw new Error('Value can\'t be converted to json')
    }

    updateOrder([...[], ...value])
    return Store.set(this._rn(name), jsonValue)
  }

  async remove(name: string): Promise<any> {
    await this._removeKey(name)
    return Store.remove(this._rn(name))
  }

  async get(name: string): Promise<any[] | null> {
    const resultJson = await Store.get(this._rn(name));
    let result: any = resultJson;

    if (resultJson) {
      try {
        result = JSON.parse(resultJson)
      } catch (error: any) {
        throw new Error(error.message)
      }
    }

    return result
  }

  async isExist(name: string): Promise<any[] | null> {
    return this.get(name)
  }

  async switch(name: string) {
    const isExist: any[] | null = await this.isExist(name);
    if (isExist) {
      const withoutOld = await this.withoutOld(isExist)
      await this.replace(name, withoutOld)
    }
    else updateOrder([])
  }

  async clear() {
    let keys = await this.keys();
    await Promise.all(keys.map((name) => this.remove(name)))
    updateOrder([])
    return
  }

  async withoutOld(orders: {
    date: string // "2021-11-18"
    time: string // "06:00 - 08:00"
  }[]): Promise<any[]> {
    // const now = dayjs(new Date()).add(1, 'day')
    const withoutOld = orders.filter((order) => {

      // eslint-disable-next-line @typescript-eslint/no-unused-vars 
      /*
      const [_, range] = order.time.split(' - ');
      const [hour, min] = range.split(':')
      const selectedEndRange = dayjs(order.date).hour(parseInt(hour)).minute(parseInt(min));
      if (now.isAfter(selectedEndRange)) {
        return false
      } */
      return true;
    })

    return withoutOld
  }

  async simulateDay(day: string) {
    const value: any = await this.get('Москва')
    let [first]: any = value;
    first.date = day
    this.add('Москва', [...value, first])
  }
}

export default OrderStore;