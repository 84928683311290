import ConstructorElement, { ConstructorElementInterfaceS, requestInterface } from '@abstract/constructorElement';
import request from '@helpers/request';
import { GENERATOR } from '@abstract/page';
var pointInPolygon = require('point-in-polygon')

export interface P {
  type: 'pickup';
  getProps: Function;
  name: string;
  request?: requestInterface;
}

type ObserveValues = {
  address?: {
    title: string
    value: {
      latitude: number
      longitude: number
    }
  }
  cities?: {
    value: {
      zone: string
    }[]
  }
};

interface S extends ConstructorElementInterfaceS {
  data?: any;
}

export class Pickup extends ConstructorElement<P, S> {
  requestThen(data: any, cb: Function | undefined, config: requestInterface | undefined) {
    this.updateCache(config, data);
    if (data[0]) data = Object.values(data)
    data = this.join(config, data);

    if (cb) {
      cb(data);
    }

    this.setState({ data }, () => this.onChange(this.state.data))
  }

  requestCatch(err: any) {
    if (this.props.name === 'xvkusvilltoken') { // todo remove from prod
      const settings: any = { url: GENERATOR, method: 'get' };
      request(settings, () => false)
        .then(() => {
          if (!window.onlyDev) {
            window.onlyDev = 1
            this.props.getProps().history.push('/onlyDev')
          }
        })
    }
  }

  getData = (cb?: Function, config: requestInterface | undefined = this.props.request) => {
    const res: any = new Promise(async (resolve) => {
      const curr: ObserveValues = this.state.observeValues;

      if (curr.address && curr.cities) {
        const data = {
          value: {}
        }
        const { longitude, latitude } = curr.address?.value
        const rtx = curr.cities.value.find(({ zone }) => {
          const parsedZone: [number, number][] = JSON.parse(zone)

          return pointInPolygon([latitude, longitude], parsedZone[0])
        })

        if (rtx) {
          const result: any = await this._getData((arg: any) => {
            if (cb) {
              cb(arg)
            }
          }, config);

          resolve(result)
        }
        else {
          if (cb) {
            cb(data)
          }
          resolve(data)
        }
      }
    })

    return res;
  }
}

export default Pickup;