import setClasses from '@helpers/setClasses';
import React, { ChangeEvent } from 'react';
import ReactComponent from "@abstract/reactComponent";

interface P {
  className?: string
  placeholder?: string
  onChange?: (value: string) => void
  defaultValue?: string
  isBcg?: boolean
}

interface S {
  value: string
}

export class Input extends ReactComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      value: props.defaultValue || ''
    } as S;
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    const { value } = e.target;
    this.setState({ value }, () => {
      if (onChange) {
        onChange(value)
      }
    })
  }

  render() {
    const { className, placeholder, isBcg } = this.props;
    const { value } = this.state;

    return (
      <input
        className={setClasses(['w-full outline-none', className], {
          'border-b border-solid border-black': !isBcg,
          'py-4 bg-bg px-4 rounded-10px': isBcg
        })}
        onChange={(e) => this.onChange(e)}
        placeholder={placeholder}
        value={value}
      />
    )
  }
}

export default Input;