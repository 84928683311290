import React from 'react';
import ReactDOM from 'react-dom';
import Navigator from './navigator';
import reportWebVitals from './reportWebVitals';
import '@styles/fonts/index.scss';
import '@styles/index.scss';
import 'dayjs/locale/ru'
import dayjs from 'dayjs';
import { registerLocale } from "react-datepicker"
import ru from 'date-fns/locale/ru';
import OrderStore from '@helpers/orderStore';
import Box from '@components/base/box';
import CE from '@helpers/CE';
dayjs.locale('ru')
registerLocale('ru', ru)
declare global {
  interface Window {
    transport: any;
    orderStore: OrderStore
    CE: CE
    box: Box
    createjs: any
    coupon: string
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Navigator />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
