import React, { forwardRef, SyntheticEvent } from 'react';
import { ReactComponent as Calendar } from '@img/calendar.svg';
import TouchableOpacity from './touchableOpacity';
import DatePickerLib from "react-datepicker"
import dayjs from 'dayjs';
import setClasses from '@helpers/setClasses';
import ReactComponent from "@abstract/reactComponent";

interface P {
  onChange?: (...args: any) => void;
  date?: Date;
  className?: string;
  format: Function;
  isRight: boolean;
  onClick: Function
  disabledDates?: { date: string }[]
}

interface S {

}

function DatePickerComponent({ date, onChange, _ref, disabledDates }: any) {
  const CustomInput = forwardRef(({ onClick }: any, ref: any) => (
    <div className="" onClick={onClick} ref={(r: any) => {
      ref(r)
      _ref(r)
    }} />
  ));

  let excludeDates: Date[] = []
  if (disabledDates && disabledDates.length) {
    excludeDates = disabledDates.map(({ date }: any) => dayjs(date).toDate())
  }

  return (
    <DatePickerLib
      locale="ru"
      selected={date}
      onChange={onChange}
      showMonthYearDropdown
      calendarStartDay={1}
      excludeDates={excludeDates}
      disabledKeyboardNavigation
      minDate={dayjs().add(1, 'day').toDate()}
      maxDate={dayjs().add(6, 'month').toDate()}
      customInput={<CustomInput />}
    />
  );
}

export class Datepicker extends ReactComponent<P, S> {
  private button: any;

  private onClick = (e: SyntheticEvent) => {
    this.button?.click()
    this.props.onClick()
  }

  public render(): JSX.Element {
    const { date, className, format, isRight, disabledDates } = this.props;

    return (
      <div className="w-full flex relative">
        <TouchableOpacity className="w-full relative z-10" onClick={this.onClick}>
          <div className={setClasses(['rounded-xl h-12 w-full items-center flex px-4 justify-between', className])}>
            <div className="text-datepicker text-15px whitespace-nowrap">
              {format(date)}
            </div>
            <div className="ml-2 h-12 flex justify-center items-center">
              <div className="w-full h-full flex justify-center items-center ">
                <Calendar />
              </div>
            </div>
          </div>
        </TouchableOpacity>

        <div className={`w-12 h-12 ${isRight ? 'right-0' : 'left-0'} bottom-0 absolute z-20`}>
          <div className="absolute z-20 left-0 top-0 w-full h-full" onClick={this.onClick} />
          <DatePickerComponent
            disabledDates={disabledDates}
            _ref={(ref: any) => this.button = ref}
            date={date}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    )
  }

  public static defaultProps = {
    onChange: () => false,
    date: new Date(),
    className: 'bg-white',
    isRight: false,
    format: (date: Date) => (
      <>
        <span>
          {dayjs(date).format('DD MMMM, ')}
        </span>
        <span className="uppercase">
          {dayjs(date).format('dd')}
        </span>
      </>
    )
  };
}

export default Datepicker;