export function parseString(value: string, type?: string) {
  let _value: any = value
  switch (value) {
    case 'false':
      _value = false;
      break;
    case 'true':
      _value = true;
      break;
    case 'undefined':
      _value = undefined;
      break;
    case 'null':
      _value = null;
      break;
  }

  switch (type) {
    case 'number':
      _value = parseInt(_value)
      break;
    case 'json':
      _value = JSON.stringify(_value)
      break;
    case 'jsonparse':
      _value = JSON.parse(_value)
      break;
  }

  return _value;
}

export default parseString;