export function jsToParams(params) {
  if (!params) return ''
  let keys = Object.keys(params);
  let str = "";
  if (keys.length) {
    keys.forEach((key) => {
      // eslint-disable-next-line eqeqeq
      if (str != "") {
        str += "&";
      }
      else {
        str += '?'
      }

      if (Array.isArray(params[key])) {
        params[key].forEach((item, index) => {
          if (index) {
            str += "&";
          }
          str += `${key}[]=${encodeURIComponent(item)}`
        })
      }
      else {
        str += key + "=" + encodeURIComponent(params[key]);
      }
    })
  }
  return str
}

export default jsToParams;