import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import DatepickerComponent from '@components/base/datepicker';
import Pulse from '@components/base/pulse';
import parseString from '@helpers/parseString';
import setClasses from '@helpers/setClasses';
import dayjs from 'dayjs';
import React from 'react';

export interface P {
  type: 'datepicker';
  caption?: any;
  name?: string;
  getProps: Function;
  defaultValue: any,
  hidden: boolean
}


interface S extends ConstructorElementInterfaceS {
  date: Date;
  value: any
}

export class Datepicker extends ConstructorElement<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      ...this.state,
      date: this.prepareDefaultValue(props)
    }

    this.autoChange()
  }

  prepareDefaultValue = (p: { defaultValue: string }): Date => {
    if (p.defaultValue) {
      let [parsed] = this.parseValue(p.defaultValue, this.state.observeValues, {}, true)
      if (parseString(parsed)) {
        return dayjs(parsed).toDate()
      }
    }
    return new Date()
  }

  async onChange(value: any) {
    value = dayjs(value).format('YYYY-MM-DD');
    await this.setAsyncState({ date: dayjs(value).toDate(), value })
    this._onChange(value);
  }

  autoChange() {
    this.onChange(this.state.date)
  }


  componentDidUpdate(prevProps: P, prevState: S) {
    const state: any = this.state;
    if (JSON.stringify(state.observeValues.defaultConditions) !== JSON.stringify(prevState.observeValues.defaultConditions)) {
      this.setState({ date: this.prepareDefaultValue(this.props) }, this.autoChange)
    }
  }

  public render(): JSX.Element {
    const { caption, hidden } = this.props;
    const { date } = this.state;

    const observeValues: any = this.state.observeValues
    const disabledDates = observeValues.disableddates?.value;

    return (
      this._render(
        <div 
          className={setClasses(['px-4 w-full'], {
            'hidden': hidden
          })}>
          <Pulse className="w-full" isActive={Boolean(this.state.externalChanged)}>
            <DatepickerComponent
              date={date}
              disabledDates={disabledDates}
              onChange={async (value: any) => {
                await this.onChange(value)
                this.log()
              }}
              onClick={() => false}
              isRight
            />
          </Pulse>
          {caption && <div className="text-10px leading-14px tracking-0.2px text-calorificDetail mt-3 pr-12">
            {caption}
          </div>}
        </div>
      )
    )
  }
}

export default Datepicker;