import ModalAbstract, { CancelSettingsInterface, P, RenderInterface, S } from '@abstract/modal';
import { AlertData } from '@components/base/alert';
import Button from '@components/base/button';
import Checkbox, { CheckboxComponentInterface } from '@components/base/checkbox';
import TouchableOpacity from '@components/base/touchableOpacity';
import dispatchToApp from '@helpers/dispatchToApp';
import setClasses from '@helpers/setClasses';
import Scroll from '@components/base/scroll';
import { ReactNode, SyntheticEvent } from 'react';

interface _P<AlertData> extends P<AlertData> {
  onDelete: Function
  closeAlert: (e?: SyntheticEvent) => any
  list: any[]
  onChange?: Function
  defaultValue?: number
  deleteData: Function
}

interface _S extends S<AlertData> {
  value: any
}

export class ModalAddress extends ModalAbstract<AlertData, _P<AlertData>, _S> {
  constructor(props: _P<AlertData>) {
    super(props);
    this.state = {
      ...this.state,
      value: props.defaultValue || 0
    }
  }


  _renderContent({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    return (
      <>
        {this._renderTop({ children, title, backSettings, cancelSettings })}
        <div className={setClasses(['h-full pt-12'], {
          "pb-24": controlsSettings,
        })}>
          {children}
        </div>
        {!!controlsSettings && this._renderControls(controlsSettings)}
      </>
    )
  }

  renderButton = ({ item, isActive, onClick }: CheckboxComponentInterface) => {
    return (
      <div className="w-full border-b border-solid border-gray-200 last:border-b-0 justify-between flex items-center">
        <TouchableOpacity onClick={onClick} className="w-full">
          <div className="flex items-center w-full py-4 mt-1">
            <div className={setClasses(['w-6 h-6 rounded-full flex justify-center items-center mr-2'], {
              "bg-gray-200": !isActive,
              "bg-green-400": isActive,
            })}>
              {!!isActive && <div className="fa fa-check text-white text-sm" />}
            </div>
            <div className="text-payment text-base mr-auto flex-1 flex-wrap">{item.title}</div>
          </div>
        </TouchableOpacity>
        {!isActive && (
          <TouchableOpacity space={10} onClick={() => this.onDelete(item)}>
            <div className="fa fa-times text-gray-300" />
          </TouchableOpacity>
        )}
      </div>
    )
  }

  onApplyDelete = (item: any) => {
    const { closeAlert, deleteData, list } = this.props;
    const { value } = this.state;

    deleteData(item).then((deletedItemIndex: number) => {
      if (value > deletedItemIndex) {
        const selected = value - 1;
        this.onChange(list[selected]?.value, selected);
      }
    });
    closeAlert();
  }

  onDelete = (item: any) => {
    const { onDelete, closeAlert } = this.props;
    onDelete({
      title: 'Удаление',
      item: {
        title: (
          <div className="mt-4">
            <span>Вы точно хотите удалить адрес: </span>
            <span className="text-green-500">{item.title}</span>
            <span>?</span>
          </div>
        )
      },
      controlsSettings: {
        title: (
          <div className="flex w-full">
            <Button title="Удалить" onClick={() => this.onApplyDelete(item)} isActive className="rounded-md py-3" touchableOpacityClassName="w-full" wrapperClassName="flex-1 mr-1" />
            <Button title="Отменить" onClick={closeAlert} className="rounded-md py-3" touchableOpacityClassName="w-full" wrapperClassName="flex-1 ml-1" />
          </div>
        )
      }
    })
  }

  onChange = (value: any, key: number) => {
    this.setState({ value: key })
  }

  onApply = () => {
    const { onChange } = this.props;
    if (onChange && typeof onChange) onChange(this.state.value)
    this._onCancel()
  }

  onOpen(...args: any[]) {
    this.setState({ value: this.props.defaultValue || 0 })
    this._onOpen(...args)
  }

  onCancel(cancelSettings?: CancelSettingsInterface, ...args: any[]) {
    this._onCancel(cancelSettings, ...args)
    this.setState({ value: 0 })
  }

  addAddress = () => {
    dispatchToApp('addAddress')
  }

  render() {
    const { list } = this.props;
    const { value } = this.state;

    return this._render({
      title: 'Мои адреса',
      children: (
        <div className="max-h-60vh flex flex-col">
          <Scroll enableTopShadow enableBottomShadow showScroll className="py-1 px-4" containerClassName="flex-1 w-full">
            {list && <Checkbox
              key={value}
              defaultValue={value}
              component={this.renderButton}
              onChange={this.onChange}
              list={list}
            />}
          </Scroll>

          <div className="px-4">
            <TouchableOpacity className="w-full" onClick={this.addAddress}>
              <div className="flex items-center w-full py-4 mt-1">
                <div className="text-base font-thin mr-auto text-green-400">Добавить адрес</div>
                <div>
                  <div className="fa fa-angle-right fa-2x text-gray-400" />
                </div>
              </div>
            </TouchableOpacity>
          </div>
        </div>
      ),
      controlsSettings: {
        title: <Button title="Подтвердить" onClick={() => this.onApply()} isActive className="rounded-md py-3" touchableOpacityClassName="w-full" />
      }
    })
  }
}

export default ModalAddress;
