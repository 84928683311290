import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export interface P {
  type: 'space'
  space: number
}

export class Space extends ReactComponent<P, {}>{
  render() {
    const { space } = this.props;
    return <div className="space" style={{ height: space }} />
  }
}

export default Space;