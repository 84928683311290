import setClasses from '@helpers/setClasses';
import React from 'react';
import { findDOMNode } from './utils';
import TWEEN from '@tweenjs/tween.js';
import ReactComponent from "@abstract/reactComponent";
import { createUseStyles } from 'react-jss'

type stylesType = {
  "scroll_line_wrapper": 'string'
  "scroll_line_inner": 'string'
  "scroll_top": 'string'
  "scroll_bottom": 'string'
}
const useStyles = createUseStyles({
  "scroll_line_wrapper": {
    backgroundColor: '#E8E8E8',
    height: 'calc(100% - 10px)',
    marginTop: '5px'
  },
  "scroll_line_inner": {
    backgroundColor: '#B4B4B4'
  },
  "scroll_top": {
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25)'
  },
  "scroll_bottom": {
    boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.25)'
  }
})

interface P {
  className?: string;
  containerClassName?: string;
  horizontal?: boolean;
  enableTopShadow?: boolean
  enableBottomShadow?: boolean;
  swapWheelAxes?: boolean;
  speed: number;
  stopScrollPropagation?: boolean;
  onScroll?: (data: calculatesInterface) => void;
  showScroll?: boolean
  scrollContainerClassName?: string
  scrollClassName?: string
  disableFade?: boolean
}

interface S {
  rect: any
  hasScroll: boolean
}

const eventTypes = {
  wheel: 'wheel',
  api: 'api',
  touch: 'touch',
  touchEnd: 'touchEnd',
  mousemove: 'mousemove',
  keyPress: 'keypress'
};

interface calculatesInterface {
  topPosition: number,
  leftPosition: number,
  realHeight: number,
  containerHeight: number,
  realWidth: number,
  containerWidth: number
}

function StyledWrapper({ children }: { children: (props: { classes: stylesType }) => JSX.Element }): JSX.Element {
  const classes: any = useStyles()
  return children({ classes }) || <></>
}

export class Scroll extends ReactComponent<P, S> {
  // refs
  wrapper: any;
  content: any;
  scroll: any;
  scrollBlock: any;
  scrollBlockContainer: any;
  shadow: any;
  shadowBottom: any;
  // refs end

  calculates: calculatesInterface = {
    topPosition: 0,
    leftPosition: 0,
    realHeight: 0,
    containerHeight: 0,
    realWidth: 0,
    containerWidth: 0
  };
  eventPreviousValues: any = {};
  state: S = {
    rect: {},
    hasScroll: false
  }
  smoothInterval: any;
  tween: any;

  componentDidMount() {
    const animate = (time: any) => {
      TWEEN.update(time)
      this.smoothInterval = setTimeout(animate, 1);
    }
    requestAnimationFrame(animate)
    document.addEventListener('updateScrolls', this.handleWindowResize)
    window.addEventListener("resize", this.handleWindowResize);
    this.wrapper.addEventListener("wheel", this.handleWheel, { passive: false })
    this.scroll.addEventListener("touchstart", this.handleTouchStart, { passive: false })
    this.scroll.addEventListener("touchmove", this.handleTouchMove, { passive: false })
    this.scroll.addEventListener("touchend", this.handleTouchEnd, { passive: false })
    this.updateContentRect()
  }

  componentWillUnmount() {
    this.tween?.stop()
    clearTimeout(this.smoothInterval)
    document.removeEventListener('updateScrolls', this.handleWindowResize)
    this.wrapper.removeEventListener("wheel", this.handleWheel)
    this.scroll.removeEventListener("touchstart", this.handleTouchStart, { passive: false })
    this.scroll.removeEventListener("touchmove", this.handleTouchMove, { passive: false })
    this.scroll.removeEventListener("touchend", this.handleTouchEnd, { passive: false })
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const rect = this.content?.getBoundingClientRect();

    this.updatePosition(0);

    if (rect.width !== prevState.rect.width) {
      this.setState({ rect });
      this.computeSizes();
    }
  }

  updateContentRect = () => {
    const rect = this.content?.getBoundingClientRect();
    if (rect) {
      this.setState({ rect })
    }
    this.computeSizes();
  }

  handleWindowResize = () => {
    this.updateContentRect();
  }

  computeSizes() {
    let result: {
      realHeight: number
      containerHeight: number
      realWidth: number
      containerWidth: number
    } = {
      realHeight: 1,
      containerHeight: 1,
      realWidth: 1,
      containerWidth: 1
    }

    if (this.scroll && this.scrollBlock) {
      let realHeight = this.scroll.offsetHeight;
      let containerHeight = this.wrapper.offsetHeight;
      let realWidth = this.scroll.offsetWidth;
      let containerWidth = this.wrapper.offsetWidth;

      const percentRFromCheight = containerHeight / realHeight
      const rectScrollContainer = this.scrollBlockContainer.getBoundingClientRect()
      const scrollBlockHeight = rectScrollContainer.height * percentRFromCheight;

      this.scrollBlockContainer.style.paddingBottom = `${scrollBlockHeight}px`
      this.scrollBlock.style.height = `${scrollBlockHeight}px`

      result = {
        realHeight: realHeight,
        containerHeight: containerHeight,
        realWidth: realWidth,
        containerWidth: containerWidth
      }
    }

    if (result.containerHeight < result.realHeight && !this.state.hasScroll) {
      if (this.props.enableBottomShadow && this.shadowBottom) {
        this.shadowBottom.style.display = "block"
      }

      this.setState({ hasScroll: true }, () => {
        this.computeSizes()
      })
    }

    return result
  }

  canScrollY(state = this.calculates) {
    let scrollableY = state.realHeight > state.containerHeight;
    return scrollableY && !this.props.horizontal;
  }

  canScrollX(state = this.calculates) {
    let scrollableX = state.realWidth > state.containerWidth;
    return scrollableX && this.props.horizontal;
  }

  canScroll(state = this.calculates) {
    return this.canScrollY(state) || this.canScrollX(state);
  }
  focusContent() {
    if (this.scroll) {
      findDOMNode(this.scroll).focus();
    }
  }

  composeNewState(deltaX: number, deltaY: number) {
    let newState: any = this.computeSizes();
    if (this.canScrollY(newState)) {
      newState.topPosition = this.computeTopPosition(deltaY, newState);
    } else {
      newState.topPosition = 0;
    }
    if (this.canScrollX(newState)) {
      newState.leftPosition = this.computeLeftPosition(deltaX, newState);
    }

    return newState;
  }

  computeTopPosition(deltaY: number, sizes: any) {
    let newTopPosition = this.calculates.topPosition - deltaY;
    return this.normalizeTopPosition(newTopPosition, sizes);
  }

  computeLeftPosition(deltaX: number, sizes: any) {
    let newLeftPosition = this.calculates.leftPosition - deltaX;
    return this.normalizeLeftPosition(newLeftPosition, sizes);
  }

  normalizeTopPosition(newTopPosition: number, sizes: any) {
    if (newTopPosition > sizes.realHeight - sizes.containerHeight) {
      newTopPosition = sizes.realHeight - sizes.containerHeight;
    }
    if (newTopPosition < 0) {
      newTopPosition = 0;
    }
    return newTopPosition;
  }

  normalizeLeftPosition(newLeftPosition: number, sizes: any) {
    if (newLeftPosition > sizes.realWidth - sizes.containerWidth) {
      newLeftPosition = sizes.realWidth - sizes.containerWidth;
    } else if (newLeftPosition < 0) {
      newLeftPosition = 0;
    }

    return newLeftPosition;
  }

  handleWheel = (e: WheelEvent) => {
    this.tween?.stop()
    let deltaY = e.deltaY;
    let deltaX = e.deltaX;

    if (this.props.swapWheelAxes) {
      [deltaY, deltaX] = [deltaX, deltaY];
    }

    deltaY = deltaY * this.props.speed;
    deltaX = deltaX * this.props.speed;


    let newState = this.composeNewState(-deltaX, -deltaY);

    if ((newState.topPosition && this.calculates.topPosition !== newState.topPosition) ||
      (newState.leftPosition && this.calculates.leftPosition !== newState.leftPosition) ||
      this.props.stopScrollPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setStateFromEvent(newState, eventTypes.wheel);
    this.focusContent();
  }

  setStateFromEvent(newState: any, eventType?: string) {
    if (this.props.onScroll) {
      this.props.onScroll(newState);
    }
    this.calculates = {
      ...this.calculates,
      ...newState,
      eventType
    }

    this.updatePosition();
  }

  updatePosition(position: number | null = null) {
    const { enableTopShadow } = this.props;
    const percent = Math.round(this.calculates.topPosition / (this.calculates.realHeight - this.calculates.containerHeight) * 100)

    if (position === 0) {
      this.calculates.leftPosition = 0
    }

    this.scrollBlock.style.top = `${percent}%`
    this.scroll.style.transform = `translate3d(-${this.calculates.leftPosition}px, -${this.calculates.topPosition}px, 0px)`
    if (!this.props.horizontal) {
      if (this.calculates.topPosition > 0) {
        if (enableTopShadow && this.shadow) {
          this.shadow.style.display = "block"
        }
      }
      else {
        if (this.shadow) {
          this.shadow.style.display = "";
        }
      }

      if (this.shadowBottom) {
        if (percent === 100) {
          this.shadowBottom.style.display = ""
        }
        else if (this.state.hasScroll) {
          this.shadowBottom.style.display = "block"
        }
      }
    }
    if (this.props.onScroll) {
      this.props.onScroll(this.calculates)
    }
  }

  handleTouchStart = (e: React.TouchEvent) => {
    this.tween?.stop()
    let { touches } = e;
    if (touches.length === 1) {
      let { clientX, clientY } = touches[0];
      this.eventPreviousValues = {
        ...this.eventPreviousValues,
        clientY,
        clientX,
        timestamp: Date.now()
      };
    }
  }

  handleTouchMove = (e: React.TouchEvent) => {
    if (this.canScroll()) {
      e.preventDefault();
      e.stopPropagation();
    }

    let { touches } = e;
    if (touches.length === 1) {
      let { clientX, clientY } = touches[0];

      let deltaY = this.eventPreviousValues.clientY - clientY;
      let deltaX = this.eventPreviousValues.clientX - clientX;

      this.eventPreviousValues = {
        ...this.eventPreviousValues,
        deltaY,
        deltaX,
        clientY,
        clientX,
        timestamp: Date.now()
      };

      this.setStateFromEvent(this.composeNewState(-deltaX, -deltaY));
    }
  }

  smooth = (from: number, to: number, cb: Function): void => {
    const coords = { from }
    this.tween = new TWEEN.Tween(coords)
      .to({ from: to }, 700)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        cb(coords.from)
      })
      .start()
  };

  handleTouchEnd = (e: React.TouchEvent) => {
    const { horizontal } = this.props;
    let { deltaX, deltaY, timestamp } = this.eventPreviousValues;
    if (typeof deltaX === 'undefined') deltaX = 0;
    if (typeof deltaY === 'undefined') deltaY = 0;
    if (Date.now() - timestamp < 200) {
      const newState = this.composeNewState(-deltaX * 10, -deltaY * 10);
      const from = horizontal ? this.calculates.leftPosition : this.calculates.topPosition;
      const to = horizontal ? newState.leftPosition : newState.topPosition;
      this.smooth(from, to, (value: number) => {
        this.calculates[horizontal ? 'leftPosition' : 'topPosition'] = value;
        this.updatePosition();
      })
    }

    this.eventPreviousValues = {
      ...this.eventPreviousValues,
      deltaY: 0,
      deltaX: 0
    };
  }

  render() {
    const { children, horizontal, className, containerClassName, enableBottomShadow, showScroll, disableFade, scrollContainerClassName } = this.props;
    const { rect, hasScroll } = this.state;

    return (
      <StyledWrapper>
        {({ classes }) => (
          <div ref={wrapper => this.wrapper = wrapper}
            className={setClasses(['overflow-hidden relative', containerClassName], {
              'animate__animated animate__faster animate__fadeIn': !disableFade
            })}>
            {!horizontal && <div ref={shadow => this.shadow = shadow} className={setClasses(["hidden bg-white h-0.5 w-full z-20 absolute left-0 top-0", classes.scroll_top])} />}
            <div ref={scroll => this.scroll = scroll}
              style={(horizontal ? { height: rect.height, width: rect.width } : {})}
              className={setClasses(['relative'], {
                'pr-4': showScroll && hasScroll
              })}
            >
              <div ref={content => this.content = content}
                className={setClasses(['flex', className], {
                  'flex-col': !horizontal,
                  'absolute left-0 top-0': horizontal
                })}>
                {children}
              </div>
            </div>
            <div className={setClasses(['absolute right-2 top-0 h-full w-2 py-1', scrollContainerClassName], {
              'hidden': !hasScroll || !showScroll
            })}>
              <div
                ref={scrollBlockContainer => this.scrollBlockContainer = scrollBlockContainer}
                className={setClasses(["w-full rounded-full relative sm:opacity-50 sm:hover:opacity-100", classes.scroll_line_wrapper])}
              >
                <div className="w-full h-full relative">
                  <div
                    ref={scrollBlock => this.scrollBlock = scrollBlock}
                    className={setClasses(["absolute left-0 w-full rounded-full", classes.scroll_line_inner])}
                  />
                </div>
              </div>

            </div>
            {!!enableBottomShadow && <div ref={shadow => this.shadowBottom = shadow} className={setClasses(["hidden bg-white h-0.5 w-full z-20 absolute left-0 bottom-0", classes.scroll_bottom])} />}
          </div>
        )}
      </StyledWrapper>
    )
  }

  static defaultProps = {
    speed: 1,
    horizontal: false,
  }
}

export default Scroll;