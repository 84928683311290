import React from 'react';
import Button from './button';
import { ReactComponent as Minus } from '@img/minus.svg';
import { ReactComponent as Plus } from '@img/plus.svg';
import declOfNum from '@helpers/declOfNum';
import ReactComponent from "@abstract/reactComponent";

interface S {
  value: number;
}

export interface P {
  defaultValue?: number;
  onChange?: Function;
  text_forms: [string, string, string]; // минута, минуты, минут
}

function Hr(): JSX.Element {
  return <div className="h-full bg-white opacity-50" style={{ width: .5 }} />
}

export class Counter<PP extends P = { text_forms: [string, string, string] }, SS extends S = { value: number }> extends ReactComponent<PP, SS> {
  constructor(props: PP) {
    super(props);
    this.state = {
      value: props.defaultValue,
    } as SS
  }

  subtract() {
    const { value } = this.state;
    if (value > 1) {
      this.setState({ value: value - 1 }, () => {
        this.onChange()
      })
    }
  }

  add() {
    const { value } = this.state;
    this.setState({ value: value + 1 }, () => {
      this.onChange()
    })
  }

  onChange() {
    const { onChange } = this.props;
    const { value } = this.state;
    if (onChange) onChange(value)
  }

  render(): JSX.Element {
    const { value } = this.state;
    const { text_forms } = this.props;

    return (
      <div className="flex">
        <Button onClick={() => this.subtract()} isActive className="h-9 w-9 rounded-l-lg">
          <Minus />
        </Button>
        <div className="h-9 flex items-center text-center justify-between bg-primary ">
          <Hr />
          <div className="px-4 text-white whitespace-nowrap text-12px leading-14.4px overflow-ellipsis font-bold">{value} {declOfNum(value, text_forms)}</div>
          <Hr />
        </div>
        <Button onClick={() => this.add()} isActive className="h-9 w-9 rounded-r-lg">
          <Plus />
        </Button>
      </div>
    )
  }

  public static defaultProps = {
    defaultValue: 0,
    onChange: (count: any) => { },
    text_forms: []
  };
}

export default Counter;