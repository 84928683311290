import React from 'react';
import Image from '@components/base/image';
import { updateDetail, updateDetailIsOpen } from '@effector/detail';
import ReactComponent from "@abstract/reactComponent";
import { updateDisabledGlobalScroll } from '@effector/disabledGlobalScroll';


export interface FoodInterface {
  id?: any;
  title: string,
  img: string,
  subTitle: string,
  calories: any;
  carbohydrates: any;
  fats: any;
  gramme: any;
  measure: any;
  proteins: any;
}

export const SAMPLEFOOD: FoodInterface = {
  id: 0,
  title: "Блинчики с творогом и сметаной",
  img: "https://food.vkusvill.ru/uploads/dishes/blinciki-s-tvorogom-i-smetanoj-kopia-min-6135f4f422da1%402x.png",
  subTitle: "блины (молоко, мука рисовая, яйцо куриное,соль, сахарозаменитель (сироп топинамбура)), творог натуральный, сметана 20%",
  calories: "850",
  carbohydrates: "40",
  fats: "50",
  gramme: "86",
  measure: "50",
  proteins: "33",
}

interface P extends FoodInterface {
  getProps: Function;
  onClick?: Function
}

export class Food extends ReactComponent<P, {}> {
  openDetail = () => {
    const { onClick } = this.props;
    let detail: any = { ...this.props }
    delete detail.onClick;
    updateDetail(detail);
    if (onClick) onClick()
    updateDetailIsOpen(true);
    updateDisabledGlobalScroll(true);
  }

  public render(): JSX.Element {
    const { title, img } = this.props;

    return (
      <div onClick={this.openDetail} className="w-full mb-2.5 px-5 py-5 flex items-center shadow-cardDish rounded-20px">
        <Image src={img} isHeightAuto className="w-20 h-20 flex items-center rounded-2xl overflow-hidden"/>
        <div className="text-center w-1/3 ml-10">
          <div className="text-xs text-basicText tracking-0.3px fooddesc">{title}</div>
        </div>
      </div>
    )
  }

  public static defaultProps = {
    title: '',
    img: '',
    subTitle: '',
  }
}

export default Food;
