import React from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import Title from './title';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import { $detailIsOpen } from '@effector/detail';
import $cache from "@effector/cache";

export interface P {
  title: string;
  appereance?: string;
  getProps: Function;
  hasBack?: any;
  isHome?: boolean;
}


export class Header extends ConstructorElement<P, ConstructorElementInterfaceS> {
  back = () => {
    if ($detailIsOpen.getState()) {
      const closer: any = document.querySelector('#detailCloser');
      if (closer) {
        closer.click()
      }
    } else {
      this.props.getProps().history.push('/')
    }
  }

  public render(): JSX.Element {
    const { title, appereance, hasBack, isHome } = this.props;

    return this._render(
      <>
        {
          isHome ?
          <div className="mb-14">
            {
              $cache.getState().datacontent && $cache.getState().datacontent.vvApp.image && <img className="w-full h-auto" src={$cache.getState().datacontent.vvApp.image.origin2x} alt='banner' />
            }
          </div>
            :
            <div className="flex items-start px-4 relative min-h-10 mt-3.5">
            {!!hasBack && (
              <div className="mr-4">
                <TouchableOpacity space={4} onClick={this.back}>
                  <div className="fa fa-angle-left fa-2x text-primary"/>
                </TouchableOpacity>
              </div>
            )}
            <div className="mr-12 pt-1 min-h-10">
              <Title type='title' title={this.parseValue(title, undefined, undefined, true)[0]} disabledMarginBottom
                     disabledPaddings appereance={appereance}/>
            </div>
        </div>
        }
      </>
    )
  }
}

export default Header;