import React, { SyntheticEvent } from 'react';
import ReactComponent from "@abstract/reactComponent";
import setClasses from '@helpers/setClasses';

interface P {
  space: number;
  showSpace: boolean | undefined;
  onClick: Function | undefined;
  className: string;
  id?: string;
  disabled?: boolean
}

export class TouchableOpacity extends ReactComponent<P, {}> {
  container: any

  onClick = (e: SyntheticEvent) => {
    const { onClick, disabled } = this.props;
    if (!disabled && onClick) onClick(e);
  }

  onKeyDown = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (!this.props.disabled)
      this.container.classList.add('touchableOpacity_active')
  }

  onKeyUp = (e: SyntheticEvent) => {
    e.stopPropagation()
    this.container.classList.remove('touchableOpacity_active')
  }

  public render(): JSX.Element {
    const { children, space, className, id, disabled } = this.props;
    let showSpace = this.props.showSpace ? 'showSpace' : '';

    return (
      <div
        ref={ref => this.container = ref}
        onKeyDown={this.onKeyDown}
        onTouchStart={this.onKeyDown}

        onKeyUp={this.onKeyUp}
        onTouchEnd={this.onKeyUp}

        onClick={this.onClick}
        id={id}
        className={setClasses([`touchableOpacity select-none ${className} ${showSpace}`], {
          'opacity-50': disabled
        })} style={{ padding: space, margin: -space }}
      >
        <div className="touchableOpacity__inner">
          {children}
        </div>
      </div>
    )
  }

  public static defaultProps = {
    space: 0,
    showSpace: false,
    className: '',
    onClick: () => false
  };
}

export default TouchableOpacity;