import setClasses from '@helpers/setClasses';
import React from 'react';
import ReactComponent from "@abstract/reactComponent";

interface P {
  className?: string
  onClick?: Function
}

export class Ghost extends ReactComponent<P> {
  static Text(className?: string): JSX.Element {
    return (
      <div className={setClasses(['bg-gray-200 h-1 mb-2 last:mb-0 gradientText', className])} />
    )
  }

  render(): JSX.Element {
    const { className, children, onClick } = this.props;
    return (
      <div onClick={() => { if (onClick) onClick() }}
        className={setClasses([' p-4 rounded-lg shadow gradient', className])}
      >
        {children}
      </div>
    )
  }
}

export default Ghost;