import React from 'react';
import setClasses from '@helpers/setClasses';
import TouchableOpacity from './touchableOpacity';
import ReactComponent from "@abstract/reactComponent";

export interface P {
  defaultValue: boolean;
  onChange: Function;
}

interface S {
  value: boolean;
}

export class Switch extends ReactComponent<P, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.defaultValue,
    }
  }

  private toggleValue = () => {
    const { value } = this.state;
    this.setState({ value: !value }, () => {
      this.props.onChange(this.state.value)
    })
  }

  public render(): JSX.Element {
    const { value } = this.state;
    return (
      <TouchableOpacity onClick={this.toggleValue}>
        <div className={setClasses(['flex w-12 h-8 rounded-full relative transition-colors duration-300'], { "bg-switch": !value, "bg-primary": value })}>
          <div className={setClasses(['bg-white left-1 w-6 h-6 rounded-full absolute top-1 transition-all duration-300 drop-shadow-2xl'], { "left-5": value, })} />
        </div>
      </TouchableOpacity>
    )
  }

  public static defaultProps = {
    defaultValue: 0,
    onChange: () => false,
  };
}

export default Switch;