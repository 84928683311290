import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export class Card extends ReactComponent {
  render() {
    const { children } = this.props;
    return (
      <div className="bg-white rounded-10px cardShadow">
        {children}
      </div>
    )
  }
}

export default Card;