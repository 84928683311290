import React from 'react';
import SwitchComponent from '@components/base/switch';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import $global, {updateGlobal} from "@effector/global";
import parseString from "@helpers/parseString";
import dispatchEvent from "@helpers/dispatchEvent";
import {setPreloader} from "@effector/preloader";

export interface P {
  type: 'title';
  title?: any;
  name: string
  getProps: Function;
  requestAbility: any
}

interface calendarConditionsType {
  date: string,
  days: number,
  time: string
  priceCutlery: number
}

export class Switch extends ConstructorElement<P, ConstructorElementInterfaceS & { value: boolean, priceCutlery: number }> {
  selected: boolean = false;
  constructor(props: P) {
    super(props)
    this.state = {
      ...this.state,
      value: false,
      priceCutlery: 0
    }
    this.autoChange()
  }

  autoChange() {
    this.onChange(this.selected)
    updateGlobal({ "isCheckCutlery": this.selected })
  }

  createBody(config: any) {
    const values = { ...this.state.observeValues }
    let body: any = {};

    config?.forEach(({ name, value, transform, type }: any) => {
      let _name: string = this.parseValue(name, values, {}, true)[0] || name;

      let [_value] = this.parseValue(value, values, {}, true);
      body[_name] = parseString(this.transform(_value, transform), type);
    })

    return body;
  }

  request(request: any, cb: Function, cbError?: Function) {
    if (request?.body) {
      const body = this.createBody(request?.body);

      this._getData((res: any) => { }, {
        ...request,
        body: Object.keys(body).map((name: string) => ({ name, value: body[name] }))
      }, body, (res: any) => {
        dispatchEvent('triggerCoupon')
      }, (err: any) => {
        if (cbError)
          cbError(err)
      })
    }
  }

  componentDidMount() {
    super.componentDidMount();
    let timer: ReturnType<typeof setInterval>;
    setPreloader(true)

    timer = setInterval(() => {
      const calendarConditionsObj: null | calendarConditionsType = $global.getState().calendarConditionsObj;

      if (calendarConditionsObj) {
        this.setState({ priceCutlery: calendarConditionsObj.priceCutlery })
        clearInterval(timer)
        setPreloader(false)
      }
    }, 100)
  }

  async onChange(value: any) {
    this.selected = value;
    this._onChange(value)
    await this.setAsyncState({ value })
    updateGlobal({ "isCheckCutlery": value })
  }

  public render(): JSX.Element {
    const { title, name, requestAbility } = this.props;
    const { priceCutlery } = this.state;
    return this._render(
      <div className="w-full px-4 mb-6">
        <div className="flex justify-between w-full pb-4 border-b border-solid border-hr">
          <div className="flex items-center">
            <div className="text-description mr-3 text-14px tracking-0.11px leading-16.8px">{title}</div>
            {name === "cutlery" && priceCutlery !== 0 &&
                <div className="bg-bg rounded-md">
                    <div className="text-14px px-2.5 py-1.5 font-bold">+{priceCutlery} ₽/день</div>
                </div>
            }
          </div>
          <div className="ml-auto">
            <SwitchComponent onChange={async (value: any) => {
              await this.onChange(value)
              this.log()
              await this.request(requestAbility, () => {})
            }} />
          </div>
        </div>
      </div>
    )
  }
}

export default Switch;
