import ConstructorElement, { ConstructorElementInterfaceS, requestInterface } from '@abstract/constructorElement';
import { GENERATOR } from '@abstract/page';
import request from '@helpers/request';

export interface P {
  type: 'hidden';
  getProps: Function;
  name: string;
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
}

declare global {
  interface Window { onlyDev: number }
}

export class Hidden extends ConstructorElement<P, S> {
  requestThen(data: any, cb: Function | undefined, config: requestInterface | undefined) {
    this.updateCache(config, data);
    if (data[0]) data = Object.values(data)
    data = this.join(config, data);

    if (cb) {
      cb(data);
    }
    
    this.setState({ data }, () => this.onChange(this.state.data))
  }

  requestCatch(err: any) {
    if (this.props.name === 'xvkusvilltoken') { // todo remove from prod
      const settings: any = { url: GENERATOR, method: 'get' };
      request(settings, () => false)
        .then(() => {
          if (!window.onlyDev) {
            window.onlyDev = 1
            this.props.getProps().history.push('/onlyDev')
          }
        })
    }
  }
}

export default Hidden;