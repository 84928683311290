import React from 'react';

export class ReactComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
  async setAsyncState(state: any) {
    return new Promise((resolve: Function) => {
      this.setState(state, () => resolve())
    })
  }
}

export default ReactComponent;