import debug from "./debug";

declare global {
  interface Window { webviewEmitter: { emit: (event: string, data: Object) => void } }
}

export const dispatchToApp: Function = (type: string, data: any) => {
  const transport: Function | undefined = window.webviewEmitter ? window.webviewEmitter.emit : undefined;
  debug(JSON.stringify({ title: 'window.webviewEmitter', data: window.webviewEmitter }))
  debug(JSON.stringify({ title: 'transport', data: transport }))
  if (transport && typeof transport === 'function') {
    try {
      transport(type, data);
    } catch (error) {
      debug(JSON.stringify({
        title: 'transport error', data: {
          type,
          data,
          error
        }
      }))
    }
  }
  else {
    console.log('App transport not found. Data: ', { type, data })
    debug(JSON.stringify({
      title: 'App transport not found. Data: ',
      type,
      data
    }))
  }
}

export default dispatchToApp;