import React from 'react';
import Unknown from '@img/unknown.png';
import A from '@components/base/a';
import ReactComponent from "@abstract/reactComponent";

export interface P {
  type: 'notdelivery';
}

export class Notdelivery extends ReactComponent<P, {}> {
  render() {
    return (
      <div className="w-full">
        <div className="flex flex-col justify-center items-center px-11 mt-36 mb-8">
          <img alt="" src={Unknown} className="mb-5 mt-7" />
          <div className="text-center mb-3 text-16px leading-19px  tracking-0.25px">К сожалению,  мы не доставляем заказы по данному адресу</div>
          <A onClick={() => {
            const element: any = document.querySelector('#address');
            if (element) element.click()
          }}>Изменить адрес доставки</A>
        </div>
      </div>
    )
  }
}

export default Notdelivery;
