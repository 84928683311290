import TouchableOpacity from '@components/base/touchableOpacity';
import React, { useEffect } from 'react';
import { ReactComponent as Basket } from '@img/basket.svg';
import Datepicker from '@components/base/datepicker';
import dayjs from 'dayjs';
import $order from '@effector/order';
import Image, { prepareUrl } from '@components/base/image';
import Button from '@components/base/button';
import dispatchEvent from '@helpers/dispatchEvent';
import IntervalPicker from '@components/base/intervalPicker';
import CustomCounter from '@components/base/customCounter';
import splitCity from '@helpers/splitCity';
import Pulse from '@components/base/pulse';
import { LogsInterface } from '.';
import $global from "@effector/global";

// interface _REF {
//   setCount: Function
//   setInterval: Function
//   setDate: Function
//   showExternalChangedDate: Function
//   showExternalChangedInterval: Function
// }

export interface P {
  title: string;
  getProps: () => any;
  subTitle: string;
  img: string;
  id: any;
  date: string;
  getAlert: Function
  disableddates: any
  price: number;
  currency: string;
  onChange: Function
  intervals: string
  deliveryDaysAll: any;
  time: string
  prices: string
  getIntervals: Function
  address: any
  parseValue: (str: string, item?: any, pureValue?: any, recurseSup?: boolean) => [any, any]
  _key: any;
  text_forms: [string, string, string]; // минута, минуты, минут
  _ref: Function
  logs: LogsInterface
  log: Function
  conditionsRequest: any
  abilityRequestCalendar: any,
  length: number,
}

interface calendarConditionsType {
  date: string,
  days: number,
  time: string
  priceCutlery: number
}

function ShopItem(props: P): JSX.Element {
  const {
    title, onChange, subTitle,
    img, price, prices, currency,
    time, text_forms, deliveryDaysAll,
    date, _key, getAlert, getProps,
    _ref, intervals, parseValue,
    address, getIntervals, logs,
    log, disableddates, conditionsRequest, abilityRequestCalendar, length
  }: P = props;

  let timeKey: number = getIntervals(intervals).findIndex((_time: string) => _time === time)
  const [count, _setCount]: [number, Function] = React.useState(parseInt(deliveryDaysAll));
  const [_date, _setDate]: [Date, Function] = React.useState(new Date(dayjs(date).toDate()));
  const [interval, _setInterval]: [number, Function] = React.useState(timeKey);
  const [isUpdateDate, _setIsUpdateDate]: [boolean, Function] = React.useState(false);
  const [pulseDate, setPulseDate]: [ReturnType<typeof setTimeout> | false, Function] = React.useState(false);
  const [pulseTime, setPulseTime]: [ReturnType<typeof setTimeout> | false, Function] = React.useState(false);
  let [_intervals] = parseValue(intervals);

  if (_intervals) {
    _intervals = _intervals.split(',')
  }

  const _prices: any[] = JSON.parse(prices);

  async function updateOrder() {
    const isCheckCutlery: null | boolean = $global.getState().isCheckCutlery;

    if (!address) {
      return
    }

    const order = [...$order.getState()];

    const getCurrentPrice = (itemOrder: any): number | null => {
      const _prices = JSON.parse(itemOrder.prices);
      let price = null;

      _prices.forEach((item: any, i: number) => {
        if (item.count === parseInt(itemOrder.deliveryDaysAll)) {
          price = item.price
        }
      })

      return price
    }

    const calendarConditionsObj: null | calendarConditionsType = $global.getState().calendarConditionsObj;

    order.forEach((item: any, i: number) => {
      const defaultPrice = getCurrentPrice(item);

      item.price = defaultPrice;
      item.sum = (defaultPrice !== null ? defaultPrice : 0) * item.deliveryDaysAll;

      if (calendarConditionsObj && isCheckCutlery) {
        item.price += calendarConditionsObj.priceCutlery
        item.sum += item.deliveryDaysAll * calendarConditionsObj.priceCutlery
      }
    })

    window.orderStore.replace(splitCity(address?.title), order);
    dispatchEvent('updatePrice');
  }

  useEffect(() => {
    document.addEventListener('updateOrder', updateOrder)

    return () => {
      document.removeEventListener('updateOrder', updateOrder)
    }
  })

  function callEventsUpdateOrder() {
    dispatchEvent('updateOrder');
    dispatchEvent('clearCoupon');
    dispatchEvent('clearCouponText');
  }

  async function setCount(count: any, _price: number) {
    _setCount(count)
    let _order = [...$order.getState()];

    _order[_key].deliveryDaysAll = count;
    callEventsUpdateOrder();
    onChange(_order);
    log(logs.edit, props)
  }

  async function setInterval(key: number) {
    _setInterval(key)
    let _order = [...$order.getState()];

    _order[_key].time = _intervals[key]
    callEventsUpdateOrder()
    onChange(_order);
  }

  async function setDate(_date: Date) {
    _setDate(_date)
    let _order = [...$order.getState()];

    _order[_key].date = dayjs(_date).format('YYYY-MM-DD');
    dispatchEvent('updateAddressListener');
    callEventsUpdateOrder()
  }

  async function onApplyDelete() {
    let list: any = [...$order.getState()];
    list.splice(_key, 1);
    await window.orderStore.add(splitCity(address?.title), list)
    getAlert()?.close()
    callEventsUpdateOrder()
    onChange(list);
    log(logs.delete, props)
    log(conditionsRequest, props)
    log(abilityRequestCalendar, props)
    _setIsUpdateDate(false);
    conditionUpdate();
    if (!list.length) {
      getProps().history.push('/')
    }
  }

  function onDelete() {
    getAlert()?.open({
      title: 'Удаление',
      item: {
        title: (
          <div className="mt-4">
            <span>Вы точно хотите удалить товар: </span>
            <span className="text-green-500">{title}</span>
            <span>?</span>
          </div>
        )
      },
      controlsSettings: {
        title: (
          <div className="flex w-full">
            <Button title="Удалить" onClick={onApplyDelete} isActive className="rounded-md py-3" touchableOpacityClassName="w-full" wrapperClassName="flex-1 mr-1" />
            <Button title="Отменить" onClick={() => getAlert()?.close()} className="rounded-md py-3" touchableOpacityClassName="w-full" wrapperClassName="flex-1 ml-1" />
          </div>
        )
      }
    })
  }

  function showExternalChangedDate() {
    if (pulseDate) clearTimeout(pulseDate)

    setPulseDate(
      setTimeout(() => {
        setPulseDate(false)
      }, 3000)
    )
  }

  function showExternalChangedInterval() {
    if (pulseTime) clearTimeout(pulseTime)

    setPulseTime(
      setTimeout(() => {
        setPulseTime(false)
      }, 3000)
    )
  }

  _ref(() => ({
    time: (intervalStr: string) => {
      const findedInterval = _intervals.findIndex((interval: string) => interval === intervalStr)
      if (findedInterval >= 0) {
        setInterval(findedInterval)
      }
    },
    date: (dateStr: string) => {
      setDate(dayjs(dateStr).toDate())
    }, show_date: showExternalChangedDate, show_time: showExternalChangedInterval
  }))

  useEffect(() => {
    const timeKey: number = getIntervals(intervals).findIndex((_time: string) => _time === time)
    if (timeKey > -1 && timeKey !== interval) {
      _setInterval(timeKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);


  const disabledParse = parseValue(disableddates, {}, {}, true)[1];
  let disabledDates: any[] = disabledParse ? Object.values(disabledParse) : [];

 const conditionUpdate = () => {
   const abilityCalendarDate: any = $global.getState().abilityCalendarDate;

   if (abilityCalendarDate) {
     const conditionDate: Date = new Date(abilityCalendarDate.date)

     if (!isUpdateDate) {
       if (_date.getTime() < conditionDate.getTime()) {
         _setDate(conditionDate);
         _setIsUpdateDate(true);

         const order = $order.getState();
         if (order[order.length - 1]) {
           order[order.length - 1].date = abilityCalendarDate.date;
         }
       }
     }
   }
 }
 conditionUpdate();

  return (
    <>
      <div className="pt-3 pb-5 w-full border-b border-solid border-hr last:border-0">
        <div className="flex items-start">
          <div
            className="mr-3 mt-1 bg-no-repeat bg-cover bg-center w-16 h-11 rounded-10px flex overflow-hidden justify-center items-center">
            <Image className="w-full h-full" src={prepareUrl(img)}/>
          </div>
          {!!(title || subTitle) && <div className="mr-auto mt-1">
            {!!title && <div className="font-bold text-15px leading-18px mb-1 last:mb-0 tracking-normal">{title}</div>}
            {!!subTitle && <div className="text-12px leading-14.4px text-basicText tracking-0.15px">{subTitle}</div>}
          </div>}

          <div className="ml-3">
            <TouchableOpacity onClick={onDelete} space={15}>
              <Basket/>
            </TouchableOpacity>
          </div>
        </div>
        <div className="flex mt-3 items-center">
          <div className="ml-20 font-bold text-15px mr-auto">{price * count} {currency}</div>
          <CustomCounter
            prices={_prices}
            text_forms={text_forms}
            defaultValue={count}
            onChange={setCount}
            subtract={() => log(logs.minus, props)}
            add={() => log(logs.plus, props)}
          />
        </div>
        {_key === length - 1 &&
            <>
                <div className="text-13px mt-5 pt-3 mb-2 tracking-0.15px border-t border-solid border-hr">
                    Дата начала и время доставки
                </div>
                <div className="text-13px mt-3 mb-2 tracking-0.15px">
                    Доставляем каждые 2 дня, чтобы внести изменения - напишите в поддержку не позже 12:00 за 2 дня до
                    доставки.
                </div>
                <div className="flex items-center">
                    <div className="w-full">
                        <Pulse isActive={pulseDate}>
                            <Datepicker
                                disabledDates={disabledDates}
                                date={_date}
                                onClick={() => {
                                  log(logs.calendar, props)
                                }}
                                onChange={(date: Date) => {
                                  setDate(date)
                                }}
                                format={(date: Date) => dayjs(date).format('DD.MM')}
                                className="bg-second"
                            />
                        </Pulse>
                    </div>
                </div>
                <div className="mt-2">
                    <Pulse isActive={pulseTime}>
                        <IntervalPicker
                            onClick={() => {
                              log(logs.time, props)
                            }}
                            defaultValue={timeKey}
                            intervals={_intervals || []}
                            className="bg-second"
                            onChange={(value) => setInterval(value)}
                        />
                    </Pulse>
                </div>
            </>
        }
      </div>
    </>
  )
}

ShopItem.defaultProps = {
  title: '',
  subTitle: '',
  img: '',
  price: 0,
  currency: '₽',
  text_forms: [],
}

export default ShopItem;
