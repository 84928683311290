import ReactComponent from '@abstract/reactComponent';
import Image from '@components/base/image';
import Scroll from '@components/base/scroll';
import TouchableOpacity from '@components/base/touchableOpacity';
import { $sortBy, $sort, updateSortBy, updateSort } from '@effector/sort';
import setClasses from '@helpers/setClasses';
import { ReactComponent as SortDown } from '@img/sort-down.svg';
import { ReactComponent as SortUp } from '@img/sort-up.svg';
import React from 'react';
import { connect } from 'effector-react';
import { combine } from 'effector';
import { AddressType } from '../address';
import { CircularProgressBar } from '@components/base/preloader';
import Button from '@components/base/button';

export type GroupItem = {
  title?: string
  list: ListItem[] | []
}

type StatusesType = {
  created: string
  cancel: string
  done: string
  building: string
}

export type BasketItem = {
  id: number | string
  name: string
  photo?: string
  subTitle?: string // not found in api
  sum: number
  quantity: number
  date: string
  time: string
} | undefined

export type ListItem = {
  id: number
  status: keyof StatusesType
  paid: boolean
  price: number
  datetime?: string
  comment?: string
  basket: BasketItem[]
  address: AddressType
}

interface P {
  className?: string
  onClose: Function
  onSelect: (selected: any) => void
  list: GroupItem[] | []
  getData: any
  waiting: any
  sort: any
  sortBy: any
}

export const Statuses: StatusesType = {
  created: 'Оформлен',
  cancel: 'Отменен',
  done: 'Завершен',
  building: 'Сборка',
}

export class List extends ReactComponent<P> {
  render() {
    const { className, onClose, list, onSelect } = this.props;
    return (
      <div className={setClasses(['pt-5 pb-1 bg-white w-full h-full absolute left-0 top-0 z-20 rounded-t-20px flex flex-col', className])}>
        <div className="px-4 flex justify-between w-full mb-5">
          <div className="font-black text-28px">Заказы</div>
        </div>
        <div className="px-4 flex justify-end cursor-pointer">
          <div onClick={() => {
            if (!this.props.waiting) {
              updateSortBy()
              this.props.getData()
            }
          }}>
            {this.props.sortBy ? <div>Сортировать по дате</div> : <div>Сортировать по id</div>}
          </div>
          <div
            className='ml-5'
            onClick={() => {
              if (!this.props.waiting) {
                updateSort()
                this.props.getData()
              }
            }}>
            {this.props.sort ? <SortUp /> : <SortDown />}
          </div>
        </div>
        <Scroll enableTopShadow showScroll className="pt-0 pb-20 px-4" scrollContainerClassName="" containerClassName="flex-1 w-full">
          {!this.props.waiting ? (
            list.map((group, key) => (
              <div key={key}>
                {group.title ? <div className="py-2 border-b border-solid border-hr text-xl font-black">{group.title}</div> : <></>}
                <div>{group.list.map((item) => (
                  <div key={item.id} className="border-b border-solid border-hr last:border-b-0">
                    <TouchableOpacity
                      onClick={() => onSelect(item)}
                      className="w-full">
                      <div className="py-3 text-14px font-bold">
                        <div className="flex items-center w-full">
                          <Image className="w-4 h-4 rounded-full mr-1" src="/img/orderlogo.png" />
                          <div>Заказ №{item.id}</div>
                          <div className="ml-auto">{Statuses[item.status]}</div>
                        </div>
                        <div className="mt-1 flex w-full justify-between">
                          <div className="text-datepicker">{item.paid ? 'Оплачен' : 'Не оплачен'}</div>
                        </div>
                      </div>
                    </TouchableOpacity>
                  </div>
                ))}</div>
              </div>
            ))
          ) : (
            <div className="relative">
              <div className="transform scale-50 absolute right-1/2 top-0">
                <div className="fa fa-spin ">
                  <CircularProgressBar
                    strokeWidth={5}
                    sqSize={50}
                  />
                </div>
              </div>
            </div>
          )}
        </Scroll>
        <Button 
          title="Назад" 
          onClick={() => onClose()} 
          isActive 
          touchableOpacityClassName="w-full fixed bottom-6" 
          className="w-11/12 rounded-10px py-3 mx-auto" 
          wrapperClassName="w-full"
        />
      </div>
    )
  }
}

const store: any = combine({ sort: $sort, sortBy: $sortBy });
export default connect<any, any>(List)(store);