import setClasses from '@helpers/setClasses';
import React from 'react';
import ReactComponent from "@abstract/reactComponent";

interface P {
  alt?: string;
  src: string;
  className?: string;
  isHeightAuto?: boolean
}

interface S {
  hasError: boolean;
  loaded: boolean
}

const notFoundImage = '/img/notfound.png'

export function prepareUrl(url: string) {
  if (!url || url === 'null') return notFoundImage
  return url;
}

export class Image extends ReactComponent<P, S> {
  state = {
    hasError: false,
    loaded: false
  }

  prepareUrl = () => {
    return this.state.hasError ? notFoundImage : prepareUrl(this.props.src)
  }

  onLoad = () => {
    this.setState({ loaded: true })
  }

  onError = () => {
    this.setState({ hasError: true })
  }

  render() {
    const { alt, className, isHeightAuto } = this.props;
    const { loaded } = this.state;

    return (
      <div className={setClasses(['relative', className])}>
        {!loaded && (
          <div className="w-full h-full absolute z-20 flex justify-center items-center">
            <div className="fa fa-spinner fa-spin" />
          </div>
        )}
        <img
          className={setClasses(['z-10'], {
            invisible: !loaded,
            'h-full': !isHeightAuto
          })}
          src={this.prepareUrl()}
          onLoad={this.onLoad}
          onError={this.onError}
          alt={alt}
        />
      </div>
    )
  }
}

export default Image;