import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import { Image as ImageComponent } from '@components/base/image';
import React from 'react';

export interface P {
  type: 'image';
  src: string;
  getProps: Function
}

export class Image extends ConstructorElement<P, ConstructorElementInterfaceS> {
  public render(): JSX.Element {
    const { src } = this.props;
    const [url] = this.parseValue(src);
    return this._render(
      <div className="w-full h-60 mt-2 flex justify-center items-center mb-9 px-4">
        <ImageComponent src={url} className="h-full rounded-2xl overflow-hidden" />
      </div>
    )
  }
}

export default Image;
