import React from 'react';

interface P {
  height: number;
  className: string;
}

function Hr({ height, className }: P): JSX.Element {
  return <div style={{ height }} className={`bg-hr w-full ${className}`} />
}

Hr.defaultProps = {
  height: 1,
  className: '',
}

export default Hr;