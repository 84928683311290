import $preloader from "@effector/preloader"
import { useStore } from "effector-react"
import React from "react"

function RenderPreloader() {
  const preloader = useStore($preloader)

  if (!preloader) return <></>
  return (
    <div className="z-50 fixed left-0 top-0 w-full h-full">
      <div className="opacity-60 bg-black w-full h-full" />
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center">
        <div className="fa fa-spin">
          <CircularProgressBar
            strokeWidth={5}
            sqSize={50}
          />
        </div>
      </div>
    </div>
  )
}
interface P {
  sqSize: number
  strokeWidth: number
}

export function CircularProgressBar(props: P) {
  // Size of the enclosing square
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  // const dashOffset = dashArray - dashArray * props.percentage / 100;

  return (
    <svg
      width={props.sqSize}
      height={props.sqSize}
      viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={props.sqSize / 2}
        cy={props.sqSize / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`} />
      <circle
        className="circle-progress"
        cx={props.sqSize / 2}
        cy={props.sqSize / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          // strokeDashoffset: dashOffset
        }} />
    </svg>
  );
}

CircularProgressBar.defaultProps = {
  sqSize: 200,
  percentage: 25,
  strokeWidth: 10
};

export default RenderPreloader