import React from 'react';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

export const updateDebug = createEvent<any[]>()

const $debug = createStore<any[]>([])

$debug.on(updateDebug, (state, nextState) => {
  return nextState
})

export function RenderDebug() {
  const debug = useStore($debug);
  return (
    <div className="w-full bg-black text-green-500 whitespace-pre-wrap overflow-scroll">
      {debug?.map((error, key) => (
        <>
          <br />
          <div key={key}>{String(error)}</div>
          <br />
          <hr />
        </>
      ))}
    </div>
  )
}

export default $debug;