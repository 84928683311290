import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import A from '@components/base/a';
import React from 'react';

interface ItemInterface {
  title: string;
  subTitle: string;
}
export interface P {
  type: 'detailSubTitle';
  buttonText: string;
  info: string;
  list: ItemInterface[]
  getProps: Function
}


export class DetailSubTitle extends ConstructorElement<P, ConstructorElementInterfaceS> {
  backToMain = () => {
    this.props.getProps().history.push('/')
  }

  public render(): JSX.Element {
    const { buttonText, info, list } = this.props;
    return this._render(
      <div className="px-4 mt-2">
        <div className="flex items-center">
          <div className="mr-1">
            <A>
              <div className="text-16px leading-19.2px" onClick={this.backToMain}>
                {buttonText}
              </div>
            </A>
          </div>
          <div className="rounded-full bg-calorificDetail w-1.5 h-1.5 ml-1 mr-1.5" />
          <div>{info}</div>
        </div>
        <div className="flex mt-5">
          {list.map(({ title, subTitle }: ItemInterface, key: number) => (
            <div key={key} className="mr-4 last:mr-0 w-14">
              <div className="text-17px font-bold whitespace-nowrap">{this.parseValue(title, undefined, undefined, true)}</div>
              <div className="text-xs text-calorificDetail">{this.parseValue(subTitle, undefined, undefined, true)}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default DetailSubTitle;