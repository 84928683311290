import React from 'react';
import Constructor from '@abstract/constructor';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';

export interface P {
  type: 'group';
  list: any[];
  request?: any;
  getProps: Function;
  requestSettings: any;
  appereance?: string;
  showGroupsThree?: boolean;
}

const APPEREANCES: any = {
  block: 'py-8 bg-bg',
  default: '',
}


export class Group extends ConstructorElement<P, ConstructorElementInterfaceS> {
  public render(): JSX.Element {
    const { list, getProps, requestSettings, appereance, showGroupsThree } = this.props;
    let className = appereance ? APPEREANCES[appereance] : APPEREANCES.default;
    if (showGroupsThree) {
      className += ' m-2 p-2 border border-solid border-red-600'
    }

    return this._render(
      <div className={className}>
        {Constructor.getComponents(list, requestSettings, getProps, true)}
      </div>
    )
  }
}

export default Group;