import React from 'react';

const react13 = isReact13(React);

export function findDOMNode(component) {
  if (!react13) {
    return component;
  } else {
    return React.findDOMNode(component);
  }
}

export function isReact13(React) {
  const { version } = React;
  if (typeof version !== 'string') {
    return true;
  }

  const parts = version.split('.');
  const major = parseInt(parts[0], 10);
  const minor = parseInt(parts[1], 10);

  return major === 0 && minor === 13;
}
