import Page from '@abstract/page';
import Button from '@components/base/button';
import { $detailIsOpen, updateDetailIsOpen } from '@effector/detail';
import { updateDisabledGlobalScroll } from '@effector/disabledGlobalScroll';
import setClasses from '@helpers/setClasses';
import Animated from '@mixins/animated';

const AP = class Base extends Page { };

export class Detail extends Animated(AP) {
  get config() {
    return { url: this.URLS.detail };
  }

  closeDetail = (props: any) => {
    updateDisabledGlobalScroll(false);
    
    this.animate({
      isOpen: props.detailIsOpen, onChange: async (detailIsOpen) => {
        updateDetailIsOpen(detailIsOpen)
      }
    })
  }

  render() {
    const props: any = this.props;

    return (
      <>
        <div className="hidden" id="detailCloser" onClick={() => this.closeDetail(props)} />
        {this.animated({
          render: (args) => (
            <div className="fixed left-0 top-0 w-full h-full z-40">
              <div className={setClasses(['absolute w-full h-full left-0 top-0', args.className], {
                animate__fadeIn: args.isOpen,
                animate__fadeOut: !args.isOpen,
              })}>
                <div className="w-full h-full bg-black opacity-50" onClick={() => this.closeDetail(props)} />
              </div>
              <div
                className={setClasses(["absolute bottom-0 left-0 w-full bg-white rounded-t-20px overflow-x-hidden flex flex-col h-90vh", args.className], {
                animate__fadeInUp: args.isOpen,
                animate__fadeOutDown: !args.isOpen,
                })}
              >
                {this._render()}
                <Button 
                  title="Назад" 
                  onClick={() => this.closeDetail(props)} 
                  isActive 
                  touchableOpacityClassName="w-full fixed bottom-6 z-50" 
                  className="w-11/12 rounded-10px py-3 mx-auto" 
                  wrapperClassName="w-full"
                />
              </div>
            </div>
          ),
          isOpen: props.detailIsOpen
        })}
      </>
    )
  }
}

export default Page.getStore({ detailIsOpen: $detailIsOpen }, Detail);