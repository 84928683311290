import address from "./address";
import header from "./header";
import title from "./title";
import basket from "./basket";
import checkbox from "./checkbox";
import menu from "./menu";
import info from "./info";
import a from "./a";
import datepicker from "./datepicker";
import price from "./price";
import input from "./input";
import submit from "./submit";
import group from "./group";
import notdelivery from "./notdelivery";
import Switch from "./switch";
import payment from "./payment";
import shop from "./shop";
import labels from "./labels";
import image from "./image";
import detailSubTitle from "./detailSubTitle";
import space from "./space";
import hidden from "./hidden";
import intervalpicker from './intervalpicker';
import mount from './mount';
import historyOrders from './historyOrders';
import pickup from './pickup';
import mobileBanner from "./mobileBanner";

export const ConstructorComponents: any = {
  header,
  address,
  title,
  mobileBanner,
  basket,
  checkbox,
  menu,
  info,
  a,
  datepicker,
  intervalpicker,
  price,
  input,
  submit,
  group,
  notdelivery,
  switch: Switch,
  payment,
  shop,
  labels,
  image,
  detailSubTitle,
  space,
  hidden,
  mount,
  historyOrders,
  pickup,
}

export default ConstructorComponents;