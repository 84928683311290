import { P as CheckboxInterface } from '@components/base/checkbox';
import Button from '@components/base/button';
import CheckboxComponent from '@components/base/checkbox';
import setClasses from '@helpers/setClasses';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import Scroll from '@components/base/scroll';
import Ghost from '@components/base/ghost';
import dayjs from 'dayjs';
import $global, {updateGlobal} from "@effector/global";

interface CheckboxItemInterface {
  title?: string;
  value?: any;
  subTitle?: string;
  description?: string;
  vvAppCardBackground?: string;
  vvAppCardColor?: string;
  vvAppDescription?: string;
  defaultValueCount?: any
}

interface ListFromInterface {
  path: string
  itemPath?: string
  transform?: {
    title: "",
    value: ""
  }
}

interface PriceInterface {
  count: number;
  price: number;
  discountPrice: number;
}

export interface P extends CheckboxInterface {
  type: 'checkbox';
  name?: string;
  list: CheckboxItemInterface[];
  listFrom?: ListFromInterface,
  appereance?: string;
  getProps: Function;
  caption?: string,
  caption2?: string,
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
}

const APPEREANCES: any = {
  default: {
    wrapper: 'ap-default',
    button: 'rounded-xl py-1.5 px-4',
    title: 'text-15px tracking-0.2px',
    subTitle: '',
    description: '',
  },
  primary: {
    wrapper: 'ap-primary w-128px h-80px flex flex-col justify-center relative',
    button: 'rounded-xl items-left',
    title: 'pt-2 px-3 text-16px font-bold whitespace-nowra relative',
    subTitle: 'pb-2 px-3 text-9px relative pt-2'
  },
  second: {
    wrapper: 'ap-second w-109px flex flex-col items-start justify-start',
    button: 'pt-2.5 pb-1.5 px-2 rounded-10px',
    title: 'text-11px',
    subTitle: 'text-9px leading-10.8px text-left mt-1',
    description: 'leading-13.2px text-left mt-2 text-11px',
  },
  third: {
    wrapper: 'ap-third h-8 w-100px',
    title: 'text-11px leading-16px whitespace-nowrap w-100px text-center',
    button: 'rounded-10px p-2'
  },
  none: {},
}

export class Checkbox extends ConstructorElement<P, S> {
  input: any;
  selected: number = 0;
  timeout: any = null;
  constructor(props: P) {
    super(props);
    this.autoChange();
  }

  helpers: any = {
    ...this.helpers,
    getSelected: (listFromData: any) => {
      if (this.props.name === 'count') {
        if (this.props.listFrom) {
          const arrItem: any = this.parseValue(this.props.listFrom.path, this.state.observeValues);
          if (listFromData && listFromData.length) {
            const index: number | undefined = listFromData.findIndex((item: any) => item.value === parseInt(arrItem[1].vvDurationDefault));

            this.selected = index || 0;
            updateGlobal({ "vvDurationDefault": index });
          } else {
            this.selected = 0;
            updateGlobal({ "vvDurationDefault": 0 });
          }
        } else {
          this.selected = 0;
          updateGlobal({ "vvDurationDefault": 0 });
        }
      }
    },
    getLowestPrice: (data: PriceInterface[]) => {
      if (data) {
        const minPrice = data.reduce(function (prev: PriceInterface, current: PriceInterface) {
          return prev.price < current.price ? prev : current;
        });

        return minPrice.discountPrice || minPrice.price;
      }

      return 0;
    },
    dateConvert: (dateStr: string) => {
      const arrDate = dateStr.split('-');
      const day = parseInt(arrDate[2]);
      const month = parseInt(arrDate[1]) - 1;
      const year = parseInt(arrDate[0]);
      const date = new Date(year, month, day);

      const getWeekDay = (dateObj: any) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[dateObj.getDay()];
      }

      return getWeekDay(date) + ', ' + dayjs(date).format('D MMMM');
    },
  }

  autoChange() {
    const { list } = this.props;
    const { data } = this.state;
    let listFromData = this.getListByFromList();

    const _data = (listFromData || list || data);
    if (_data && _data[this.selected]) {
      this.onChange(_data[this.selected].value, this.selected);
    }
  }

  renderPreloader() {
    const { appereance } = this.props;
    const _appereance: any = appereance ? APPEREANCES[appereance] : APPEREANCES.default;
    const elements: number[] = [0, 1, 2];

    return (
      <div className="w-full flex pl-4">
        {elements.map(key => (
          <Ghost key={key} className={setClasses(['mr-2 last:mr-0', _appereance?.wrapper, _appereance?.button])}>
            {!!_appereance?.title && Ghost.Text('w-full mb-4 last:mb-0')}
            {!!_appereance?.subTitle && Ghost.Text(`w-full`)}
            {!!_appereance?.subTitle && Ghost.Text(`w-1/2`)}
          </Ghost>
        ))}
      </div>
    )
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.listFrom) {
      let listFromDataPrev = this.getListByFromList(prevProps.listFrom)
      let listFromData = this.getListByFromList(prevProps.listFrom)
      if (JSON.stringify(listFromDataPrev) === JSON.stringify(listFromData)) {
        this.autoChange()
      }

      this.helpers.getSelected(listFromDataPrev);

      if (this.props.name === 'count' && this.input) {
        this.input.setState({value: this.selected});

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const { list } = this.props;
          const { data } = this.state;
          let listFromData = this.getListByFromList();

          const _data = (listFromData || list || data);

          if (_data && _data[this.selected]) {
            this.input.onClick(this.selected, this.selected);
          }
        }, 1000)
      }
    }
  }

  async onChange(value: any, key: number = 0) {
    this.selected = key;
    this._onChange({ value, key })

    if (this.props.name === 'count' && this.input) {
      this.input.setState({value: this.selected});
    }
  }

  getListByFromList(listFrom: ListFromInterface | undefined = this.props.listFrom): undefined | any[] {
    if (listFrom) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [_, data]: [any, any] = this.parseValue(listFrom.path, this.state.observeValues);
      if (data) {
        if (listFrom.itemPath) {
          data = ConstructorElement.recurseSelect(listFrom.itemPath.split('.'), data, true)
        }
      }

      if (data) {
        if (listFrom.transform) {
          return this.transform(data, listFrom.transform)
        }
        return data
      }
    }

    return undefined
  }

  public render(): JSX.Element {
    const { list, appereance, caption, caption2 } = this.props;
    const { data } = this.state;
    const _appereance: any = appereance ? APPEREANCES[appereance] : APPEREANCES.default;
    const listByFromList = this.getListByFromList();

    return (
      this._render(
        <>
          <Scroll horizontal className="px-4">
            <CheckboxComponent
              ref={input => this.input = input}
              list={listByFromList || list || data}
              defaultValue={this.props.name === 'count' ? $global.getState().vvDurationDefault : this.selected}
              onChange={async (value: any, key: number) => {
                await this.onChange(value, key)
                this.log()
              }}
              component={(props: any) => (
                <div className={setClasses(['mr-2 last:mr-0 min-w-10', _appereance?.wrapper])}>
                  <Button
                    customDefaultBg={appereance === "primary"}
                    {...props}
                    isCenter={appereance !== "primary" && appereance !== "second"}
                    isPrograms={appereance === "primary"}
                    vvAppCardBackground={props.item.vvAppCardBackground}
                    vvAppCardColor={props.item.vvAppCardColor}
                    className={setClasses(['w-full', _appereance?.button], {
                      "h-full": appereance === "primary",
                    })}
                    wrapperClassName={setClasses(['w-full'], {
                      "h-full": appereance === "primary",
                    })}
                    touchableOpacityClassName={setClasses(['w-full'], {
                      "h-full": appereance === "primary",
                    })}
                  >
                    {!!props.item.title && <div className={_appereance?.title}>
                      {appereance === "third" ? this.helpers.dateConvert(props.item.title) : props.item.title
                    }</div>}
                    {!!props.item.subTitle && appereance !== "primary" ? (
                      <div className={_appereance?.subTitle}>
                        {props.item.subTitle.replace('MINPRICEVALUE', this.helpers.getLowestPrice(props.item.value.prices))}
                      </div>
                    ) : <div className={_appereance?.subTitle}>
                      {props.item.vvAppDescription}
                    </div>}
                  </Button>
                  {!!props.item.description && <div className={_appereance?.description}>{props.item.description}</div>}
                </div>
              )}
            />
          </Scroll>

          {!!caption && <div className="px-4 w-full">
            <div className="text-10px leading-14px tracking-0.2px text-calorificDetail mt-3 pr-12">
              {caption}
              <p>{!!caption2 && caption2}</p>
            </div>
          </div>}
        </>
      )
    )
  }

  static defaultProps = {
    width: '',
  }
}

export default Checkbox;
