import ModalAbstract, { CancelSettingsInterface, P, S } from '@abstract/modal';
import Button from '@components/base/button';
import Checkbox, { CheckboxComponentInterface } from '@components/base/checkbox';
import TouchableOpacity from '@components/base/touchableOpacity';
import dispatchToApp from '@helpers/dispatchToApp';
import setClasses from '@helpers/setClasses';
import { ICONS } from '.';
import Scroll from '@components/base/scroll';
import { LogsInterface } from './index';

interface _P<Data> extends P<Data> {
  closeAlert: Function
  list: any[]
  onChange?: Function
  defaultValue?: number
  log: Function
  logs: LogsInterface
}

interface _S<Data> extends S<Data> {
  value: any
}

interface AlertData { }

export class ModalPayment extends ModalAbstract<AlertData, _P<AlertData>, _S<AlertData>> {
  constructor(props: _P<AlertData>) {
    super(props);
    this.state = {
      ...this.state,
      value: props.defaultValue
    }
  }

  renderButton({ item, isActive, onClick }: CheckboxComponentInterface) {
    return (
      <TouchableOpacity onClick={onClick} className="w-full border-b border-solid border-gray-200">
        <div className="flex items-center w-full py-4 mt-1">
          <div className="rounded-5px w-10 h-6 overflow-hidden bg-second mr-2">
            <img alt="" src={ICONS[item.type] || ICONS.VALUTE} />
          </div>
          <div className="text-payment text-base mr-auto">{item.pan}</div>
          <div className={setClasses(['w-6 h-6 rounded-full flex justify-center items-center'], {
            "bg-gray-200": !isActive,
            "bg-green-400": isActive,
          })}>
            {!!isActive && <div className="fa fa-check text-white text-sm" />}
          </div>
        </div>
      </TouchableOpacity>
    )
  }

  onChange = async (value: any, key: number) => {
    await this.setAsyncState({ value: key })
  }

  onApply = () => {
    const { onChange } = this.props;
    this.props.log(this.props.logs.change)
    if (onChange && typeof onChange) onChange(this.state.value)
    this._onCancel()
  }

  onOpen(...args: any[]) {
    this.setState({ value: this.props.defaultValue || 0 })
    this._onOpen(...args)
  }

  onCancel(cancelSettings?: CancelSettingsInterface, ...args: any[]) {
    this.props.log(this.props.logs.close)
    this._onCancel(cancelSettings, ...args)
    this.setState({ value: 0 })
  }

  addCard = () => {
    dispatchToApp('addCard')
  }

  render() {
    const { list } = this.props;
    const { value } = this.state;
    return this._render({
      title: 'Способы оплаты',
      children: (
        <div className="max-h-60vh flex flex-col">
          <Scroll enableTopShadow enableBottomShadow className="py-1 px-4" containerClassName="flex-1 w-full">
            {list && <Checkbox
              defaultValue={value}
              component={this.renderButton}
              onChange={async (value: any, key: number) => {
                await this.onChange(value, key)
                this.props.log(this.props.logs.select, value)
              }}
              list={list}
            />}

          </Scroll>

          <div className="px-4">
            <TouchableOpacity className="w-full" onClick={this.addCard}>
              <div className="flex items-center w-full py-4 mt-1">
                <div className="rounded-5px w-10 h-6 overflow-hidden bg-second mr-2">
                  <img alt="" src={ICONS.VALUTE} />
                </div>
                <div className="text-base font-thin mr-auto text-green-400">Добавить карту</div>
                <div>
                  <div className="fa fa-angle-right fa-2x text-gray-400" />
                </div>
              </div>
            </TouchableOpacity>
          </div>
        </div>
      ),
      controlsSettings: {
        title: <Button title="Подтвердить" onClick={this.onApply} isActive className="rounded-md py-3" touchableOpacityClassName="w-full" />
      }
    })
  }
}

export default ModalPayment;