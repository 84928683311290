type Return = Record<string, any>


interface WaitEvent<CustomReturn> {
  run: () => Promise<CustomReturn>
  clear: () => void
}


export function waitEvent<CustomReturnType = Return>(eventName: string, timeout?: number): WaitEvent<CustomReturnType> {
  let isCleared: boolean = false;

  return {
    run: async () => {
      return new Promise((resolve, reject) => {
        let timer: ReturnType<typeof setTimeout>;

        function handler(event: any) {
          document.removeEventListener(eventName, handler)
          clearTimeout(timer)
          if (isCleared) return false
          const data: CustomReturnType = event.detail;
          let res: any = { eventName, data };
          resolve(res)
        }

        document.addEventListener(eventName, handler)

        if (timeout && timeout >= 0) {
          timer = setTimeout(() => {
            document.removeEventListener(eventName, handler)
            if (isCleared) return false
            reject('waitEvent timeout')
          }, timeout);
        }
      })
    },
    clear: () => isCleared = true
  }
}

export default waitEvent;

// const t = waitEvent('eventName') //return type any
// const t1 = waitEvent<{ test: string }>('eventName1') // return type { test: string }
// const t2 = waitEvent('eventName2')

// t.run()
//   .then(res => {
//     console.log(res)
//   })

// t1.clear()
// t2.run()
//   .then(res => {
//     console.log(res.test)
//   })
//   .catch(console.log)