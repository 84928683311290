import React from 'react';
import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS } from '@abstract/constructorElement';
import Animated, { AnimatedValue } from '@mixins/animated';
import TouchableOpacity from '@components/base/touchableOpacity';
import setClasses from '@helpers/setClasses';
import List, { GroupItem, ListItem } from './list';
import Inner from './inner';
import { CircularProgressBar } from '@components/base/preloader';
interface P {
  type: 'historyOrders';
  title: string;
}

interface S {
  isOpen: AnimatedValue
  isOpenInner: AnimatedValue
  selected?: null | false | ListItem
  touchStart: number
  touchEnd: number
  minSwipeDistance: number
  list: GroupItem[] | []
}

const CE = class Base extends ConstructorElement<ConstructorElementInterfaceP & P, ConstructorElementInterfaceS & S> { };

export class HistoryOrders extends Animated(CE) {
  constructor(props: ConstructorElementInterfaceP & P) {
    super(props)
    this.state = {
      ...this.state,
      waiting: true,
      isOpen: false,
      isOpenInner: false,
      selected: null,
      touchStart: null || 0,
      touchEnd: null || 0,
      minSwipeDistance: 50,
      list: []
    }
  }

  onOpenMain = () => {
    this.log()
    this.animate({ isOpen: this.state.isOpen, onChange: async (isOpen) => this.setAsyncState({ isOpen }) })
  }

  onCloseMain = async () => {
    await this.animate({ isOpen: this.state.isOpen, onChange: async (isOpen) => this.setAsyncState({ isOpen }) })
    await this.setAsyncState({
      isOpenInner: false,
      selected: null
    })
  }

  onSelect = async (selected: any) => {
    await this.setAsyncState({ selected })
    await this.animate({ isOpen: this.state.isOpenInner, onChange: async (isOpenInner) => this.setAsyncState({ isOpenInner }) })
    this.log()
  }

  onCloseInner = async () => {
    await this.animate({ isOpen: this.state.isOpenInner, onChange: async (isOpenInner) => this.setAsyncState({ isOpenInner }) })
    await this.setAsyncState({ selected: null })
    this.log()
  }

  // swipe
  onTouchStart = async (e: React.TouchEvent) => {
    e.stopPropagation();
    this.setState({
      touchStart: e.targetTouches[0].clientY,
      touchEnd: null || 0,
    })
  }

  onTouchMove = async (e: React.TouchEvent) => {
    e.stopPropagation();
    this.setState({
      touchEnd: e.targetTouches[0].clientY
    })
  }

  onTouchEnd = async () => {
    const {touchStart, touchEnd, minSwipeDistance} = this.state;

    const distance = touchStart - touchEnd
    const isTopSwipe = distance > minSwipeDistance
    const isBottomSwipe = distance < -minSwipeDistance
    if (isTopSwipe || isBottomSwipe) {
      if (!isTopSwipe) {
        this.onCloseMain();
      }
    }
  }

  public render(): JSX.Element {
    const { title } = this.props;
    const { isOpen, isOpenInner, data, selected, waiting } = this.state;
    return (
      <div>
        <div className="px-4 -mt-3 mb-2.5">
          <TouchableOpacity disabled={waiting} className="w-full" onClick={this.onOpenMain}>
            <div className="flex justify-between items-center text-sm font-bold w-full bg-white shadow rounded-15px py-3 px-4">
              <div>{title}</div>
              <div>
                {waiting ? (
                  <div className="relative h-5 w-5">
                    <div className="transform scale-50 absolute -right-4 -top-4">
                      <div className="fa fa-spin">
                        <CircularProgressBar
                          strokeWidth={5}
                          sqSize={50}
                        />
                      </div>
                    </div>
                  </div>
                ) : this.state.data?.length}
              </div>
            </div>
          </TouchableOpacity>
        </div>


        {this.animated({
          render: (args) => (
            <div className="fixed left-0 top-0 w-full h-full z-40">
              <div className={setClasses(['absolute w-full h-full left-0 top-0', args.className], {
                animate__fadeIn: args.isOpen,
                animate__fadeOut: !args.isOpen,
              })}>
                <div className="w-full h-full bg-black opacity-50" onClick={this.onCloseMain} />
              </div>

              <div
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                className={setClasses(["absolute bottom-0 left-0 w-full bg-white rounded-t-20px flex flex-col h-90vh", args.className], {
                animate__fadeInUp: args.isOpen,
                animate__fadeOutDown: !args.isOpen,
                })}
              >
                <List
                  list={[{
                    list: data
                  }]}
                  onClose={this.onCloseMain}
                  className={`${args.className} animate__fadeIn`}
                  onSelect={this.onSelect}
                  getData={this.getData}
                  waiting={waiting}
                />
                {this.animated({
                  render: (args) => (
                    <Inner
                      selected={selected}
                      onClose={this.onCloseMain}
                      onCloseInner={this.onCloseInner}
                      className={setClasses(['shadow-nextPage', args.className], {
                        animate__fadeInRight: args.isOpen,
                        animate__fadeOutRight: !args.isOpen,
                      })}
                    />
                  ),
                  isOpen: isOpenInner
                })}
              </div>
            </div>
          ),
          isOpen
        })}

      </div>
    )
  }
}

export default HistoryOrders;
