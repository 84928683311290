import { AnimatedValue } from '@mixins/animated';
import { createEvent, createStore } from 'effector';

export const updateDetail = createEvent<any>()
export const updateDetailIsOpen = createEvent<AnimatedValue>()

const $detail = createStore<any>({})
export const $detailIsOpen = createStore<AnimatedValue>(false)

$detail.on(updateDetail, (_, nextState) => nextState)
$detailIsOpen.on(updateDetailIsOpen, (_, nextState) => nextState)

export default $detail;