import ModalAbstract, { P, S, RenderInterface, ControlsSettings } from '@abstract/modal';
import setClasses from '@helpers/setClasses';
import { ReactNode } from 'react';

export interface AlertData {
  onClose?: Function
  item?: {
    title: JSX.Element | string | ReactNode
  },
  title?: string | ReactNode
  controlsSettings?: ControlsSettings
}

export class ModalAlert extends ModalAbstract<AlertData, P<AlertData>, S<AlertData>> {
  height: string = 'h-5/6';
  _renderContent({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    return (
      <>
        {this._renderTop({ children, title, backSettings, cancelSettings })}
        <div className={setClasses(['h-full pt-12'], {
          "pb-24": controlsSettings,
        })}>
          {children}
        </div>
        {!!controlsSettings && this._renderControls(controlsSettings)}
      </>
    )
  }

  _render({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    const { isOpen } = this.state;

    if (!isOpen) return <></>
    return (
      <div className="fixed left-0 top-0 w-full h-full z-50 ">
        <div className={setClasses(["absolute w-full h-full left-0 top-0 animate__animated animate__faster"], {
          "animate__fadeIn": typeof isOpen === 'boolean',
          "animate__fadeOut": typeof isOpen === 'string',
        })} onClick={() => this._onCancel(cancelSettings)}>
          <div className="w-full h-full bg-black opacity-50" />
        </div>
        <div className={setClasses(["absolute bottom-0 left-0 w-full bg-white rounded-t-xl flex flex-col animate__animated animate__faster"], {
          "animate__fadeInUp": typeof isOpen === 'boolean',
          "animate__fadeOutDown": typeof isOpen === 'string',
        })}>
          {this._renderContent({ children, title, backSettings, cancelSettings, controlsSettings })}
        </div>
      </div>
    )
  }

  render() {
    const data: any = this.state.data;
    return this._render({
      title: data?.title,
      children: (
        <div className="px-4">
          {data?.item?.title}
        </div>
      ),
      controlsSettings: data?.controlsSettings
    })
  }
}

export default ModalAlert;