import sleep from "@helpers/sleep";
import { ReactNode } from "react";

type Constructor = new (...args: any[]) => {};

export type AnimatedValue = boolean | 'closing'

function Animated<TBase extends Constructor>(Base: TBase) {
  return class Animating extends Base {
    animated({ render, isOpen }: { render: (args: { isOpen: boolean, className: string }) => ReactNode, isOpen: AnimatedValue }): ReactNode {
      if (!isOpen) return <></>
      return render({ isOpen: isOpen === true || (isOpen === 'closing' && false), className: 'animate__animated animate__faster' })
    }

    async animate({ isOpen, onChange }: { isOpen: AnimatedValue, onChange: (value: AnimatedValue) => Promise<any> }): Promise<any> {
      if (isOpen) {
        if (isOpen === true) {
          await onChange('closing')
          await sleep(500)
          await onChange(false)
        }
        else {
          // console.warn('closing')
        }
      }
      else {
        await onChange(true)
      }
    }
  };
}

export default Animated;