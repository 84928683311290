import AComponent from '@components/base/a';
import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export interface P {
  type: 'a';
  title?: any;
}

export class A extends ReactComponent<P, {}> {
  public render(): JSX.Element {
    const { title } = this.props;
    return (
      <div className="px-4 tracking-0.2px'">
        <AComponent>{title}</AComponent>
      </div>
    )
  }
}

export default A;