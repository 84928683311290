import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from '@pages/main';
import Shop from '@pages/shop';
import Detail from '@pages/detail';
import OrderStore from '@helpers/orderStore';
import Generator from '@pages/generator'; // todo remove from prod
import CE from '@helpers/CE';

function Navigator() {
  useEffect(() => {
    window.orderStore = new OrderStore();
    window.CE = new CE();
  }, [])

  const routeTypeFix: any = {
    exact: true
  }

  return (
    <Router>
      <Route component={Detail} /> {/* modal for save prev page state */}
      <Switch>
        <Route path="/" {...routeTypeFix} component={Main} />
        <Route path="/shop" {...routeTypeFix} component={Shop} />
        <Route path="/onlyDev" {...routeTypeFix} component={Generator} /> {/* todo remove from prod */}
      </Switch>
    </Router>
  );
}

export default Navigator;
