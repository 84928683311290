import React from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import ModalPayment from './modal';
import { CRUDINTERFACE } from '../address';
import ConstructorElement, { ConstructorElementInterfaceS, LogInterface, requestInterface } from '@abstract/constructorElement';

export interface LogsInterface {
  edit: LogInterface
  apply: LogInterface
  mount: LogInterface
  close: LogInterface
  select: LogInterface
  change: LogInterface
}

export interface P {
  type: 'payment';
  getProps: Function;
  CRUD: CRUDINTERFACE
  defaultValue?: number
  logs: LogsInterface
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
  error?: string;
  selected?: number
}

export const ICONS: any = {
  'APPLEPAY': '/img/payment/applepay.png',
  'VISA': '/img/payment/visa.png',
  'VALUTE': '/img/payment/valute.png',
  'MASTERCARD': '/img/payment/mastercard.png',
}

export class Payment extends ConstructorElement<P, S> {
  modal?: any;
  constructor(props: P) {
    super(props);
    this.state = {
      ...this.state,
      selected: props.defaultValue
    }
    this.autoChange()
  }

  openPayment = () => {
    this.log(this.props.logs.edit)
    this.log(this.props.logs.mount)
    this.modal?.open()
  }

  getData = async (cb?: Function) => {
    const config: requestInterface | undefined = this.props.CRUD.READ;
    return this._getData(cb, config);
  }

  requestThen(data: any, cb: Function | undefined, config: requestInterface | undefined) {
    this.updateCache(config, data);
    data = this.join(config, data);
    data.value = Object.values(data.value);


    if (cb) {
      cb(data.value);
    }


    this.setState({ data: data.value, error: undefined }, () => {
      this.autoChange()
    })
  }

  autoChange() {
    this.onChange(this.state.selected)
  }

  onChange = async (selected: any) => {
    const { data } = this.state;
    const selectedAddr: any = (data && selected !== undefined && data[selected]);
    await this.setAsyncState({ selected, selectedAddr })
    this._onChange(selectedAddr);
  }

  public render(): JSX.Element {
    const { selected, data } = this.state;
    const selectedAddr: any = (data && selected !== undefined && data[selected]);

    return (
      <>
        <div className="px-4 pb-5 mb-6 border-b border-solid border-hr">
          <TouchableOpacity onClick={this.openPayment} className="w-full">
            <div className="flex items-center w-full">
              <div className="rounded-5px w-10 h-6 overflow-hidden bg-second mr-2">
                <img alt="" src={ICONS[selectedAddr && selectedAddr.type] || ICONS.VALUTE} />
              </div>
              <div className="font-bold text-payment text-base mr-auto">{!selectedAddr ? 'Выберите метод оплаты' : `${selectedAddr.pan}`}</div>
              <div className="text-description opacity-50 text-xs ml-4">Изменить</div>
            </div>
          </TouchableOpacity>
        </div>
        <ModalPayment
          log={(log: LogInterface, value: any) => {
            this.log(log, value)
          }}
          logs={this.props.logs}
          controller={controller => this.modal = controller}
          closeAlert={() => this.modal?.close()}
          onChange={async (value: any) => {
            await this.onChange(value)
            this.log(this.props.logs.apply)
          }}
          defaultValue={selected}
          list={data}
        />
      </>
    )
  }
}

export default Payment;
