import React from 'react';
import setClasses from '@helpers/setClasses';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';

export interface P {
  type: 'title';
  title?: any;
  appereance?: string;
  disabledPaddings?: boolean;
  disabledMarginBottom?: boolean;
  getProps: Function
}

const APPEREANCES: any = {
  default: 'text-gray-900 text-20px leading-24px font-black',
  description: 'text-10px  text-calorificDetail',
  detail: 'text-description capitalize-first text-14px leading-19.6px mb-12',
  h1: 'text-h1 text-28px leading-33.6px font-extrabold tracking-min0.01em mb-1',
  h2: 'tracking-0.25px text-18px leading-22px font-black',
  none: '',
}

export class Title extends ConstructorElement<P, ConstructorElementInterfaceS> {
  public render(): JSX.Element {
    const { title, appereance, disabledPaddings, disabledMarginBottom } = this.props;
    const _appereance: string = appereance ? APPEREANCES[appereance] : APPEREANCES.default;
    return this._render(
      <div className={setClasses(['capitelize', _appereance], {
        'px-4': !disabledPaddings,
        'mb-5': !disabledMarginBottom
      })}>{this.parseValue(title)}</div>
    )
  }

  static defaultProps = {
    getProps: () => false // fix warning
  }
}

export default Title;