import Button from '@components/base/button';
import Card from '@components/base/card';
import Hr from '@components/base/hr';
import declOfNum from '@helpers/declOfNum';
import ShopItem from './item';
import $order from '@effector/order';
import { connect } from 'effector-react';
import { combine } from 'effector';
import ModalAlert from '@components/base/alert';
import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS, LogInterface } from '@abstract/constructorElement';
import dispatchEvent from '@helpers/dispatchEvent';
import { updateGlobal } from '@effector/global';

export interface LogsInterface {
  add: LogInterface
  delete: LogInterface
  plus: LogInterface
  minus: LogInterface
  edit: LogInterface
  calendar: LogInterface
  time: LogInterface
}

export interface P extends ConstructorElementInterfaceP {
  type: 'shop';
  text_forms?: [string, string, string];
  intervals?: string
  addText?: string;
  getProps: Function;
  disableddates: string;
  order: any[];
  logs: LogsInterface
  conditionsRequest: any;
  abilityRequestCalendar: any;
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
}

export class Shop extends ConstructorElement<P, S> {
  items: any[] = [];
  alert: any;

  componentDidMount() {
    const { order } = this.props;

    super.componentDidMount()
    dispatchEvent('shopshowaddress')
    updateGlobal({ "coupon": '' });
    updateGlobal({ "calendarConditionsObj": null })
    updateGlobal({ "abilityCalendarDate": null })

    order.forEach((item: any, i: number) => {
      const prices = JSON.parse(item.prices);

      prices.forEach((priceItem: any, i: number) => {
        if (priceItem.count === parseInt(item.deliveryDaysAll)) {
          item.price = priceItem.price;
          item.sum = (priceItem.price !== null ? priceItem.price : 0) * item.deliveryDaysAll;
        }
      })
    })
  }

  componentDidUpdate(prevProps: P) {
    if (JSON.stringify(this.props.order) !== JSON.stringify(prevProps.order)) {
      const { order } = this.props;
      if (!order.length) this.props.getProps().history.push('/')
    }
  }

  externalUpdate({ item, key }: { item: any, key: number }) {
    const element: any = this.items[key]
    Object.keys(item).forEach(name => {
      const func: Function | undefined = element()[name];
      if (func) func(item[name])
    })
    return this
  }

  showExternalChanged({ item, key }: { item: any, key: number }) {
    const element: any = this.items[key]
    Object.keys(item).forEach(name => {
      const func: Function | undefined = element()['show_' + name];
      if (func) func(item[name])
    })
    return this;
  }

  public render(): JSX.Element {
    const { order, text_forms, addText, intervals, logs, conditionsRequest, abilityRequestCalendar } = this.props;

    return this._render(
      <>
        <div className="w-full px-4 mt-4">
          <Card>
            <div className="py-4">
              <div className="text-gray-900 font-black text-20px leading-24px px-4 pb-2">Корзина</div>
              <Hr />
              <div className="px-3 pt-4">
                <div className="text-sm font-bold">{order.length} {declOfNum(order.length, ['товар', 'товара', 'товаров'])}</div>
                {order.map((item: any, key: number) => (
                  <ShopItem
                    log={(log?: LogInterface, values?: any) => this.log(log, values)}
                    logs={logs}
                    conditionsRequest={conditionsRequest}
                    abilityRequestCalendar={abilityRequestCalendar}
                    onChange={(value: any) => this.onChange(value)}
                    {...item}
                    length={order.length}
                    _ref={(ref: Function) => this.items[key] = ref}
                    getIntervals={this.getIntervals}
                    getProps={this.props.getProps}
                    address={this.state.observeValues.address}
                    getAlert={() => this.alert}
                    disableddates={this.props.disableddates}
                    key={`${key}${item.price}${item.count}`}
                    intervals={intervals}
                    parseValue={this.parseValue}
                    _key={key}
                    text_forms={text_forms}
                  />
                ))}
                {!!addText && (
                  <Button
                    onClick={() => {
                      this.log(this.props.logs.add)
                      this.props.getProps().history.push('/')
                    }}
                    isActive
                    title={addText}
                    touchableOpacityClassName="w-full"
                    className="w-full rounded-10px py-4"
                    wrapperClassName="w-full mt-5"
                  />
                )}
              </div>
            </div>
          </Card>
        </div>


        <ModalAlert
          controller={controller => this.alert = controller}
        />
      </>
    )
  }
}

const store: any = combine({ order: $order });
export default connect(Shop)(store)
