import localforage from 'localforage';
const LocalForage: any = localforage;

type SomeInterface = [string, any?]

async function multi(proccess: string, some: SomeInterface[]) {
  let result: any = {};
  (await Promise.all(some.map(async (item) => await LocalForage[proccess](...item)))).forEach((value, index) => {
    result[some[index][0]] = value;
  })
  return result;
}

export const Store = {
  get: async (name: string) => await localforage.getItem<string>(name),
  getAll: async (keys: string[]) => {
    return multi('getItem', keys.map((key: string) => ([key])))
  },
  set: async (name: string, value: string) => await LocalForage.setItem(name, value),
  remove: async (name: string) => await LocalForage.removeItem(name),
  setAll: async (keyValues: any) => {
    let body: SomeInterface[] = Object.keys(keyValues).map((key) => ([key, keyValues[key]]))
    return multi('setItem', body)
  },
  clear: async () => {
    if (await LocalForage.length() > 0) {
      return await LocalForage.clear();
    }
    return;
  },
};

export default Store;
