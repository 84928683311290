import React, { ReactNode } from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import { ReactComponent as Close } from '@img/close.svg';
import Scroll from '@components/base/scroll';
import setClasses from '@helpers/setClasses';
import ReactComponent from "@abstract/reactComponent";
import { updateDisabledGlobalScroll } from '../effector/disabledGlobalScroll';

export interface ControllerInterface<Data> {
  open: (data: Data) => void
  close: (cancelSettings?: CancelSettingsInterface) => void
}

export interface P<Data> {
  controller?: (controller: ControllerInterface<Data>) => void
}

export interface S<Data> {
  isOpen: any
  data?: Data
}


interface BackSettingsInterface {
  title?: string | ReactNode
  method: Function
}
export interface CancelSettingsInterface {
  title?: string | ReactNode
  method?: Function
}
export interface ControlsSettings {
  title?: string | ReactNode | JSX.Element
}
export interface RenderInterface {
  children: ReactNode
  title?: string | ReactNode
  backSettings?: BackSettingsInterface
  cancelSettings?: CancelSettingsInterface
  controlsSettings?: ControlsSettings
}


export class ModalAbstract<Data, ExtP extends P<Data>, ExtS extends S<Data>> extends ReactComponent<ExtP, ExtS> {
  animateTimer: any;
  height: string = '';
  constructor(props: ExtP) {
    super(props)

    this.state = {
      isOpen: false,
      data: {}
    } as ExtS
  }

  componentDidMount() {
    if (this.props.controller) {
      this.props.controller({
        open: (...args: any[]) => this.onOpen(...args),
        close: (...args: any[]) => this.onCancel(...args)
      })
    }
  }

  _renderBackIcon() {
    return (
      <div className="fa fa-angle-left fa-2x" />
    )
  }

  _renderBack({ title, method }: BackSettingsInterface) {
    let _title = 'Назад';
    if (!title || typeof title === 'string') {
      if (typeof title === 'string') _title = title;
      return (
        <TouchableOpacity onClick={() => this._onBack({ title, method })} className="z-20 relative" space={5}>
          <div className="flex items-center">
            {this._renderBackIcon()}
            <div className="ml-2 mt-1">{_title}</div>
          </div>
        </TouchableOpacity>
      )
    }

    return title
  }

  _renderTitle(title?: string | ReactNode) {
    return (
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center z-10">
        <div className="mt-1">{title}</div>
      </div>
    )
  }

  _renderCancel(cancelSettings?: CancelSettingsInterface) {
    let _title: string | ReactNode = <Close />
    if (cancelSettings?.title) {
      if (typeof cancelSettings.title === 'string') {
        _title = cancelSettings.title;
      }
      else return cancelSettings.title;
    }

    return (
      <TouchableOpacity onClick={() => this.onCancel(cancelSettings)} className="z-20 relative" space={5}>
        {_title}
      </TouchableOpacity>
    )
  }

  _renderTop({ title, backSettings, cancelSettings }: RenderInterface) {
    return (
      <div className="absolute left-0 top-2 flex justify-between items-center px-4 h-10 w-full">
        {!!backSettings ? this._renderBack(backSettings) : <div />}
        {!!title && this._renderTitle(title)}
        {this._renderCancel(cancelSettings)}
      </div>
    )
  }

  _onBack({ method }: BackSettingsInterface) {
    if (method && typeof method === 'function') method()
  }

  _onOpen(data?: Data) {
    clearTimeout(this.animateTimer)
    let state: S<Data> = { isOpen: true }
    if (data) {
      state.data = data;
    }
    updateDisabledGlobalScroll(true)
    this.setState(state)
  }

  onOpen(...args: any[]) {
    this._onOpen(...args)
  }

  _onCancel(cancelSettings?: CancelSettingsInterface, ...args: any[]) {
    const data: any = this.state.data;
    if (typeof this.state.isOpen !== 'string') {
      this.setState({ isOpen: 'animate' })
      clearTimeout(this.animateTimer)
      this.animateTimer = setTimeout(() => {
        const state: any = { isOpen: false, data: {} }
        this.setState(state)
        updateDisabledGlobalScroll(false)
        if (cancelSettings) {
          const { method } = cancelSettings;
          if (method && typeof method === 'function') method()
        }
        if (data?.onClose) {
          data?.onClose()
        }
      }, 500); // animate__faster - duration 1s
    }
  }

  onCancel(cancelSettings?: CancelSettingsInterface, ...args: any[]) {
    if (this.state.isOpen) {
      this._onCancel(cancelSettings, ...args)
    }
  }

  _renderControls(controlsSettings: ControlsSettings) {
    if (!controlsSettings) return <></>
    return (
      <div className="w-full h-24 absolute bottom-0 left-0 pt-4 px-4">
        {controlsSettings?.title}
      </div>
    )
  }

  _renderContent({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    return (
      <>
        {this._renderTop({ children, title, backSettings, cancelSettings })}
        <div className={setClasses(['h-full pt-12'], {
          "pb-24": controlsSettings,
        })}>
          <Scroll enableTopShadow enableBottomShadow showScroll containerClassName="h-full">
            {children}
          </Scroll>
        </div>
        {!!controlsSettings && this._renderControls(controlsSettings)}
      </>
    )
  }

  _render({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    const { isOpen } = this.state;
    if (!isOpen) return <></>
    return (
      <div className="fixed left-0 top-0 w-full h-full z-40">
        <div className={setClasses(["absolute w-full h-full left-0 top-0 animate__animated animate__faster"], {
          "animate__fadeIn": typeof isOpen === 'boolean',
          "animate__fadeOut": typeof isOpen === 'string',
        })} onClick={() => this.onCancel(cancelSettings)}>
          <div className="w-full h-full bg-black opacity-50" />
        </div>
        <div className={setClasses(["absolute bottom-0 left-0 w-full bg-white rounded-t-xl flex flex-col animate__animated animate__faster", this.height], {
          "animate__fadeInUp": typeof isOpen === 'boolean',
          "animate__fadeOutDown": typeof isOpen === 'string',
        })}>
          {this._renderContent({ children, title, backSettings, cancelSettings, controlsSettings })}
        </div>
      </div>
    )
  }
}

export default ModalAbstract;