import React from 'react';
import ReactComponent from "@abstract/reactComponent";

interface labelInterface {
  title: string
}

export interface P {
  type: 'labels',
  list: labelInterface[]
}

export class Labels extends ReactComponent<P, {}> {
  public render(): JSX.Element {
    const { list } = this.props;
    return (
      <div className="flex items-start flex-wrap px-4 w-full">
        {list.map((item: labelInterface, key: number) => <div key={key} className="bg-second rounded-xl mr-4 px-4 py-2.5 text-15px leading-15px tracking-0.2px mb-4">{item.title}</div>)}
      </div>
    )
  }
}

export default Labels;