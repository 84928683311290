import React from 'react';
import ReactComponent from "@abstract/reactComponent";

export interface P {
  component?: (props: CheckboxComponentInterface) => JSX.Element,
  list: any[],
  onChange?: (value: any, key: number) => void,
  defaultValue?: any
}

interface S {
  value: number;
}

export interface CheckboxComponentInterface {
  item: any,
  key: number,
  onClick: Function,
  isActive: boolean,
}

export class Checkbox extends ReactComponent<P, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.defaultValue,
    }
  }

  reset = () => {
    this.setState({ value: this.props.defaultValue })
  }

  onClick = (value: number) => this.setState({ value }, () => {
    const { onChange } = this.props;
    let _value = this.props.list[value].value || this.props.list[value] || value;
    if (onChange) onChange(_value, value);
  })

  public render(): JSX.Element {
    const { component, list } = this.props;
    const Component: any = component;
    const { value } = this.state;
    return (
      <>
        {!!list?.length && list.map((item, key) => {
          const props: CheckboxComponentInterface = {
            item,
            key,
            onClick: () => this.onClick(key),
            isActive: Boolean(key === value)
          };
          return <Component {...props} />
        })}
      </>
    )
  }

  public static defaultProps = {
    list: [],
    defaultValue: -1,
    component: () => false,
    onChange: () => false
  };
}

export default Checkbox;