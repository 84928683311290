import ConstructorElement, { ConstructorElementInterfaceP, ConstructorElementInterfaceS } from '@abstract/constructorElement';
import IntervalPicker from '@components/base/intervalPicker';
import Pulse from '@components/base/pulse';

export interface P extends ConstructorElementInterfaceP {
  type: 'intervalpicker';
  caption?: any;
  intervals: string
}

interface S extends ConstructorElementInterfaceS {
  interval: number
}

export class Intervalpicker extends ConstructorElement<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      ...this.state,
      interval: -1
    }

    this.autoChange()
  }

  helpers: any = {
    ...this.helpers,
    getInterval: () => {
      const { interval } = this.state;
      const selectedInterval = this.getIntervals(this.props.intervals)[interval];
      return selectedInterval
    }
  }

  async onChange(interval: number) {
    await this.setAsyncState({ interval })
    const selectedInterval = this.getIntervals(this.props.intervals)[interval];
    this._onChange(selectedInterval);
  }

  externalUpdate(intervalStr: string) {
    const intervals = this.getIntervals(this.props.intervals)
    const interval = intervals.findIndex((interval: string) => interval === intervalStr)

    if (interval >= 0) {
      this.onChange(interval)
    }
    return this
  }

  autoChange() {
    this.onChange(this.state.interval)
  }

  getIntervals = (intervals: string) => {
    let [_intervals] = this.parseValue(intervals);
    if (_intervals) {
      _intervals = _intervals.split(',')
    }

    return _intervals
  }

  public render(): JSX.Element {
    const { caption } = this.props;
    const { interval } = this.state;
    const intervals = this.getIntervals(this.props.intervals)

    return (
      this._render(
        <div className="px-4 w-full">
          <Pulse className="w-full" isActive={Boolean(this.state.externalChanged)}>
            <IntervalPicker
              onClick={() => false}
              defaultValue={interval}
              intervals={intervals || []}
              className="bg-white"
              onChange={async (value) => {
                await this.onChange(value)
                this.log();
              }}
            />
          </Pulse>
          <div className="text-10px leading-14px tracking-0.2px text-calorificDetail mt-3 pr-12">
            {caption}
          </div>
        </div>
      )
    )
  }
}

export default Intervalpicker;
