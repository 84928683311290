import React from 'react';
import { ReactComponent as Edit } from '@img/edit.svg';
import TouchableOpacity from '@components/base/touchableOpacity';
import ModalAddress from './modal';
import ModalAlert from '@components/base/alert';
import ConstructorElement, { requestInterface, ConstructorElementInterfaceS, ConstructorElementInterfaceP } from '@abstract/constructorElement';
import { updateAddress } from '@effector/address';
import $order from '@effector/order';
import splitCity from '@helpers/splitCity';
import dispatchEvent from '@helpers/dispatchEvent';
import debug from '@helpers/debug';
import request from '@helpers/request';
import setClasses from '@helpers/setClasses';
import waitEvent from '@helpers/waitEvent';

export interface CRUDINTERFACE {
  CREATE?: requestInterface,
  READ?: requestInterface,
  UPDATE?: requestInterface,
  DELETE?: requestInterface,
  CHANGE?: requestInterface,
}

export interface P extends ConstructorElementInterfaceP {
  type: 'address';
  title?: any;
  appereance?: string;
  CRUD: CRUDINTERFACE
  defaultValue?: any
}

export interface AddressType {
  title: string
  value: {
    comments: string
    doorphone: string
    entrance: string
    flat: string
    floor: string
    id?: number | string
    latitude?: number | string
    longitude?: number | string
    street: string
    adress_str?: string
    card_number?: string
    corr_addr?: string
    name_other?: string
    phone_other?: string
    private_home?: number
    str_par?: string
  }
}

interface S extends ConstructorElementInterfaceS {
  data?: any;
  error?: string;
  selected: number
  show: boolean
}

export class Address extends ConstructorElement<P, S> {
  modal?: any;
  alert?: any;
  showtimer?: any;
  constructor(props: P) {
    super(props);
    this.state = {
      ...this.state,
      show: false,
      selected: props.defaultValue ? parseInt(this.parseValue(props.defaultValue)[0]) : props.defaultValue
    } as S

    this.autoChange()
    waitEvent('shopshowaddress').run().then(() => this.show(400))
    waitEvent('storeready', 1000).run().then().catch(() => {
      if (window.location.pathname !== '/shop') {
        this.show(1)
      }
    })
    this.updateAddressListener = this.updateAddressListener.bind(this);
  }

  updateAddressListener() {
    this.autoChange()
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener('updateAddressListener', this.updateAddressListener);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener('updateAddressListener', this.updateAddressListener)
  }

  openAddress = () => {
    this.log()
    this.modal?.open()
  }

  openAddressRemove = (...args: any[]) => this.alert?.open(...args)

  getData = async (cb?: Function) => {
    const config: requestInterface | undefined = this.props.CRUD.READ;
    return this._getData(cb, config);
  }

  deleteData = async (item: any, cb?: Function) => {
    return new Promise((resolve, reject) => {
      const config: requestInterface | undefined = this.props.CRUD.DELETE;
      let _config: any = config ? { ...config } : {};

      this._getData(cb, _config, { item }, (data: any, cb: Function | undefined) => {
        if (cb) {
          cb(data.result);
        }

        const deletedItemIndex = this.state.data.findIndex((_item: any) => _item.value.id === item.value.id);

        if (this.state.selected > deletedItemIndex) {
          const selected = this.state.selected - 1;
          this.onChange({ selected })
        }

        this.setState({ data: this.state.data.filter((_item: any) => _item.value.id !== item.value.id), error: undefined }, () => resolve(deletedItemIndex))
      });
    })
  }

  async autoChange() {
    const selected = this.props.defaultValue ? parseInt(this.parseValue(this.props.defaultValue)[0]) : this.props.defaultValue;
    if (selected !== this.state.selected) {
      await this.setAsyncState({ selected })
    }

    this.onChange({ selected: this.state.selected, isAutoChange: true })
  }

  observeGetDataCallback(data: any) { }

  async requestThen(data: any, cb: Function | undefined, config: requestInterface | undefined) {
    this.updateCache(config, data);
    data = this.join(config, data);

    if (cb) {
      cb(data.list);
    }

    this.setState({ data: data.list, error: undefined }, () => {
      this.autoChange()
    })
  }

  onChange = ({ selected, isAutoChange }: { selected: any, isAutoChange?: boolean }) => {
    const apply = () => {
      this.setState({ selected })
      const { data } = this.state;
      const selectedAddr: any = (data && selected !== undefined && data[selected]);
      // if (!isAutoChange) {
      updateAddress({ selected })
      // }

      this._onChange(selectedAddr);
    }

    if (
      // eslint-disable-next-line
      (selected != this.state.selected)
      && (this.state.data[selected] && this.state.data[this.state.selected])
      && (splitCity(this.state.data[selected].title) !== splitCity(this.state.data[this.state.selected].title))
    ) {
      window.orderStore
        .switch(splitCity(this.state.data[selected].title))
        .then(() => {
          apply()
        })
    }
    else apply()
  }

  show = (timer: number) => {
    clearTimeout(this.showtimer)
    if (!this.state.show) {
      this.showtimer = setTimeout(() => {
        this.setState({ show: true })
      }, timer);
    }
  }

  async componentDidUpdate(prevProps: P, prevState: S) {
    if (this.state.data?.length && !prevState.data?.length) {
      if (!$order.getState()?.length) {
        const { data, selected } = this.state;
        const selectedAddr: any = (data && selected !== undefined && data[selected]);
        if (selectedAddr) {
          const cityName: string = splitCity(selectedAddr.title);
          const isExist = await window.orderStore.isExist(cityName);
          if (isExist) {
            window.orderStore.switch(cityName)
            dispatchEvent('storeready')
            this.show(400)
          }
        }
      }
    }
  }

  static getSubTitle(selectedAddr: AddressType): string {
    const list: { prefix: string, value: any }[] = [
      { prefix: 'кв', value: selectedAddr.value.flat },
      { prefix: 'подъезд', value: selectedAddr.value.entrance },
      { prefix: 'домофон', value: selectedAddr.value.doorphone },
      { prefix: 'этаж', value: selectedAddr.value.floor },
    ];

    let result: string = list
      .filter((item) => item.value)
      .map((item) => `${item.prefix} ${item.value}`)
      .join(', ');

    if (result.length) {
      result += '. '
    }

    if (selectedAddr.value.comments) {
      result += selectedAddr.value.comments
    }

    return result
  }

  changeAddressInAPP(selected: number) {
    const id = this.state.data[selected].value.id;

    const config: any = this.props.CRUD.CHANGE;
    let { settings } = this.createSettings(config, this.state.observeValues);
    settings.body.id_customer_address = id

    this.setState({ waiting: true })

    request(settings, (isPreloader: boolean) => this.setState({ isPreloader }))
      .then()
      .catch((err) => {
        debug(JSON.stringify({ settings, err }))
      })
      .then(() => {
        this.setState({ waiting: false })
      })
  }

  public render(): JSX.Element {
    const { title } = this.props;
    const { data, selected } = this.state;
    const selectedAddr: any = (data && selected !== undefined && data[selected]);

    return (
      <>
        <div className={setClasses(['px-4 mb-1 last:mb-0 mt-2 animate__animated animate__faster opacity-0 '], {
          "animate__fadeIn": this.state.show
        })}>
          {!!title && <div className="text-16px leading-19.2px tracking-0.15px">{title}</div>}
          <div className="flex items-start">
            <div className="text-16px leading-19.2px tracking-0.15px mr-1">{!selectedAddr ? 'Укажите адрес доставки' : `${selectedAddr.title}`}</div>
            <div className="ml-auto">
              <TouchableOpacity id="address" space={10} onClick={this.openAddress}>
                <Edit />
              </TouchableOpacity>
            </div>
          </div>
          {!!selectedAddr && <div className="text-gray-500 text-12px leading-14.4px  tracking-0.15px whitespace-nowrap overflow-ellipsis w-full overflow-hidden">{Address.getSubTitle(selectedAddr)}</div>}
        </div>
        <ModalAddress
          controller={controller => this.modal = controller}
          onDelete={this.openAddressRemove}
          closeAlert={() => this.alert?.close()}
          onChange={(selected: any) => {
            this.changeAddressInAPP(selected)
            this.onChange({ selected })
          }}
          defaultValue={selected}
          deleteData={this.deleteData}
          list={data}
        />
        <ModalAlert
          controller={controller => this.alert = controller}
        />
      </>
    )
  }
}

export default Address;
