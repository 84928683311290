import React, { SyntheticEvent } from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import setClasses from '@helpers/setClasses';
import ReactComponent from "@abstract/reactComponent";

interface P {
  title: string | undefined;
  isActive: boolean,
  isCenter: boolean,
  isPrograms: boolean,
  onClick: (e: SyntheticEvent) => any
  className: string;
  wrapperClassName: string;
  touchableOpacityClassName: string;
  customDefaultBg?: boolean;
  vvAppCardBackground?: string;
  vvAppCardColor?: string;
}

export class Button extends ReactComponent<P, {}> {
  onClick = (e: SyntheticEvent) => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  }

  public render(): JSX.Element {
    const { title, children, isActive, isCenter, isPrograms, className, wrapperClassName, touchableOpacityClassName,
      customDefaultBg, vvAppCardBackground, vvAppCardColor } = this.props;
    return (
      <div className={wrapperClassName}>
        <TouchableOpacity onClick={this.onClick} className={touchableOpacityClassName}>
          <div className={setClasses(['flex flex-col', className], {
            "bg-second text-basicText": !isActive && !customDefaultBg,
            "bg-primary text-white": !isPrograms && isActive,
            "opacity-75": isPrograms && !isActive,
            "items-center justify-center": isCenter
          })} style={{
            backgroundColor: isPrograms && vvAppCardBackground ? `${vvAppCardBackground}` : '',
            color: isPrograms && vvAppCardColor ? `${vvAppCardColor}` : ''
          }}>
            <div className={setClasses([], {
              "border-4 border-solid border-primary rounded-xl h-full": isPrograms && isActive,
              "border-4 border-solid border-transparent rounded-xl h-full": isPrograms && !isActive,
            })}>
              {!!title && <div className="text-16px tracking-min0.24px whitespace-nowrap">{title}</div>}
              {children}
            </div>
            </div>
        </TouchableOpacity>
      </div>
  )
  }

  public static defaultProps = {
    title: '',
    isActive: false,
    isCenter: true,
    isPrograms: false,
    onClick: () => false,
    className: '',
    wrapperClassName: '',
    touchableOpacityClassName: '',
  };
}

export default Button;