import React, { SyntheticEvent } from 'react';
import { ReactComponent as Clock } from '@img/clock.svg';
import TouchableOpacity from './touchableOpacity';
import dayjs from 'dayjs';
import setClasses from '@helpers/setClasses';
import Button from './button';
import Scroll from './scroll';
import randomKey from '@helpers/randomkey';
import ReactComponent from "@abstract/reactComponent";

interface P {
  onChange?: (...args: any) => void;
  className?: string;
  intervals: string[]
  placeholder?: string
  defaultValue?: number
  onClick: Function
}

interface S {
  isOpen: boolean
  active: number
}
const MAININTERVALCLASS: string = 'js-mainInterval';
export class IntervalPicker extends ReactComponent<P, S> {
  id?: string;

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      active: props.defaultValue
    }

    this.id = randomKey(10)
  }

  onToggle = (e: SyntheticEvent) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen })
    this.props.onClick()
  }

  onChange = (active: number) => {
    this.setState({ active, isOpen: false }, () => {
      if (this.props.onChange)
        this.props.onChange(active)
    })
  }

  handleClick = (e: MouseEvent) => {
    if (!this.isThatBlock(e.target)) {
      this.setState({ isOpen: false })
    }
  }

  isThatBlock = (target: any): boolean => {
    if (target) {
      if (target.tagName === 'BODY') return false
      const contain = target.classList?.contains(MAININTERVALCLASS)
      if (contain) {
        const id = target.getAttribute('data-id');
        return id === this.id
      }

      return this.isThatBlock(target.parentElement)
    }

    return false
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      if (this.state.active !== this.props.defaultValue) {
        if (this.props.defaultValue !== undefined) {
          this.setState({ active: this.props.defaultValue })
        }
      }
    }
  }

  render(): JSX.Element {
    const { className, intervals, placeholder } = this.props;
    const { isOpen, active } = this.state;

    return (
      <div className={`w-full flex relative ${MAININTERVALCLASS}`} data-id={this.id}>
        <TouchableOpacity className="w-full relative z-10" onClick={this.onToggle}>
          <div className={setClasses(['rounded-xl h-12 w-full items-center flex px-4 justify-between', className])}>
            <div className="text-datepicker text-15px whitespace-nowrap">
              {active >= 0 ? intervals[active] : placeholder}
            </div>
            <div className="ml-2 h-12 flex justify-center items-center">
              <div className="w-full h-full flex justify-center items-center">
                <Clock />
              </div>
            </div>
          </div>
        </TouchableOpacity>


        {!!isOpen && <div className="w-12 h-12 right-0 top-2 absolute z-20">
          <div className="absolute right-0 top-full customTimePicker visible z-20 bg-white w-40 h-32">
            <Scroll showScroll containerClassName="w-full h-full">
              <div className="w-full">
                {intervals?.map((interval, key: number) => (
                  <Button
                    key={key}
                    customDefaultBg
                    touchableOpacityClassName="w-full"
                    className="w-full px-3 py-2.5 hover:text-primary"
                    isActive={key === active}
                    onClick={() => this.onChange(key)}
                  >
                    <div className="text-left w-full timeInterval">{interval}</div>
                  </Button>
                ))}
              </div>
            </Scroll>
          </div>
        </div>}
      </div>
    )
  }

  public static defaultProps = {
    className: 'bg-white',
    placeholder: 'Укажите время',
    format: (start: Date, end: Date) => `${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`,
    onChange: () => false
  };
}

export default IntervalPicker;