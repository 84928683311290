import React from 'react';
import Food, { FoodInterface } from '@components/constructor/menu/food';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import Ghost from '@components/base/ghost';
import $global, {updateGlobal} from "@effector/global";

interface GroupInterface {
  list: FoodInterface[],
}

export interface P {
  type: 'menu',
  groups?: GroupInterface[]
  name?: string;
  getProps: Function;
}

export interface S extends ConstructorElementInterfaceS {
  data?: any;
}

export class Menu extends ConstructorElement<P, S> {
  clickData: any = false;
  helpers: any = {
    ...this.helpers,
    getSelected: () => {
      return this.clickData
    }
  }

  renderPreloader() {
    const elements: number[] = Array.from({ length: $global.getState().itemsCount }, (v, i) =>  i + 1);

    return (
      <div className="flex flex-col pl-4 h-full mb-5 w-full">
        {elements.map(key => (
          <Ghost key={key} className={'mr-4 flex items-center h-120px py-5 mb-2.5'}>
            {Ghost.Text('w-20 h-20')}
            {Ghost.Text('ml-5 w-6/12')}
          </Ghost>
        ))}
      </div>
    )
  }

  public render(): JSX.Element {
    const { groups } = this.props;
    const { data } = this.state;
    const _groups: GroupInterface[] | undefined = (data && data[0] && data[0].groups)
    const viewKbju: boolean = $global.getState().viewKbju;

    let itemsCount = 0;
    _groups?.forEach((group: any) => {
      itemsCount += group.list.length
    })

    if (itemsCount) {
      updateGlobal({ "itemsCount": itemsCount })
    }

    const _list = (groups || _groups);
    let calories: number = 0;
    let proteins: number = 0;
    let fats: number = 0;
    let carbohydrates: number = 0;

    const roundAndTrimDecimal = (number: number): number => {
      let roundedNumber: number | string = Number(number.toFixed(1));

      if (Number.isInteger(roundedNumber)) {
        roundedNumber = roundedNumber.toFixed(0);
      }

      return Number(roundedNumber);
    }

    if (_list && viewKbju) {
      for (let i = 0; i < _list.length; i++) {
        _list[i].list.forEach((item: FoodInterface) => {
          calories += Number(item.calories);
          proteins += Number(item.proteins);
          fats += Number(item.fats);
          carbohydrates += Number(item.carbohydrates);
        })
      }
    }

    return this._render(
      <div className="w-full">
        <div className="px-4">
          {_list?.map((group: any, key: number) => (
            <div key={group.id} className="flex flex-wrap">
              {group.list.map((item: FoodInterface, key: number) => <Food
                {...{...item, getProps: this.props.getProps}}
                onClick={() => {
                  this.clickData = item.id;
                  this.log()
                }}
                key={item.id}
              />)}
            </div>
          ))}
          {_list && viewKbju && <div className="mb-2 mt-5 h-10">
              <div className="text-11px leading-14px tracking-0.2px text-calorificDetail mb-3 text-center">
                  Среднее значение по блюдам ±50 ккал
              </div>
              <div className="px-4 flex justify-center">
                  <div className="text-calorificDetail mr-4 last:mr-0 w-16">
                      <div className="text-12px font-semibold">{roundAndTrimDecimal(calories)}</div>
                      <div className="text-12px">ккал</div>
                  </div>
                  <div className="text-calorificDetail mr-4 last:mr-0 w-16">
                      <div className="text-12px font-semibold">{roundAndTrimDecimal(proteins)}</div>
                      <div className="text-12px">белки</div>
                  </div>
                  <div className="text-calorificDetail mr-4 last:mr-0 w-16">
                      <div className="text-12px font-semibold">{roundAndTrimDecimal(fats)}</div>
                      <div className="text-12px">жиры</div>
                  </div>
                  <div className="text-calorificDetail mr-4 last:mr-0 w-16">
                      <div className="text-12px font-semibold">{roundAndTrimDecimal(carbohydrates)}</div>
                      <div className="text-12px">углеводы</div>
                  </div>
              </div>
          </div>}
        </div>
      </div>
    )
  }
}

export default Menu;